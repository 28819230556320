<template>
  <a-col>
    <a-col v-for="(item, index) in addTaskList" :key="index" class="add-li">
      <div class="spilt-lileft">
        <p class="handleColor"></p>
        <a-input v-model:value="item.taskDescription" class="spilt-ipt"
                 placeholder="添加下级部门任务；根据主任务来拆分下级任务"
                 @blur="blurDelete(index)"/>
      </div>
      <div class="spilt-liright">
        <a-range-picker
            v-model:value="item.taskCycle"
            :disabledDate="disabledDate"
            :placeholder="['开始时间', '结束时间']"
            class="task-pick"
            valueFormat="YYYY-MM-DD"
            @blur="blurDelete(index)" @change="onChangeDate"/>
        <div class="margin-lr" style="width:300px">
<!--          <a-cascader-->
<!--              v-model:value="item.operator"-->
<!--              :load-data="loadData"-->
<!--              :options="departmentList"-->
<!--              placeholder="执行人"-->
<!--              style="width:120px"-->
<!--              @blur="blurDelete(index)"-->
<!--              @change="(e, r) => {onChange(index, r)}"-->
<!--          />-->




          <a-tree-select
              v-model:value="value"
              tree-data-simple-mode
              style="width: 300px"
              :dropdown-style="{ width: '200px', overflow: 'auto' }"
              :tree-data="treeData"
              placeholder="选择执行人"
              :maxTagCount="2"
              :load-data="onLoadData"
              tree-checkable
              allow-clear
              @change="treeOnChange"
          />



        </div>
        <a-button
            :loading="item.loading"
            class="spilt-btn"
            size="small"
            type="primary"
            @click="saveSplitd(index)"
        >
          保存
        </a-button>
      </div>
    </a-col>
    <a-col class="flex flex-direction">
      <div class="spilt-div flex align-center" @click="changeAdd">
        <div class="border-bottom">
          <img src="../../../../assets/add.svg">
          <p>拆分主任务分发至下级部门</p>
        </div>
      </div>
      <div class="record">
        <img src="../../../../assets/task.svg"/>
        <a-textarea
            v-model:value="pr"
            auto-size
            class="ipt"
            placeholder="进度记录：保持更新，实时记录任务进展的最新情况"
            @blur="onBlurEdit"/>
      </div>
    </a-col>
  </a-col>
</template>

<script>
import {defineComponent, inject, reactive, ref, watch} from 'vue';
import {depList, depListFilterSelf} from '@/api/dingApi';
import {message} from 'ant-design-vue';
import {taskBreakUp, editRecord} from '@/api/task';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'AddItem',
  components: {},
  props: {
    itemInfo: {
      type: Object,
    },
  },
  setup(props, {emit}) {
    const taskItemInfo = inject('taskItemInfo');
    const performanceTaskBook = inject('performanceTaskBook');
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    const addTaskList = ref([]);
    const departmentList = ref([]);

    const pr = ref(props.itemInfo.progressRecord);

    // 点击添加时
    const changeAdd = async () => {
      try {
        if (addTaskList.value.length > 0) return;
        addTaskList.value.push({
          taskDescription: null,
          taskCycle: null,
          operator: null,
          selectOperator: null,
          loading: false,
        });

        // 默认加载第一级
        depList({
          id: userInfo.department[0],
          userid: userInfo.userid,
          peers: 1,
        }).then((response) => {
          console.log(response)
          const {deptUserList, kvDeptList} = response.data.data;
          let users = filterTree(deptUserList.map((item) => ({...item, pid: 0, isLeaf: true})), userInfo.department[0], 'name')
          let kvDeps = filterTree(kvDeptList.map((item) => ({...item, pid: 0, checkable: false})), userInfo.department[0], 'name')
          treeData.value = [...users, ...kvDeps]
          console.log(treeData.value)
        });
      } catch (e) {
        console.log(e);
      }
    };

    // 拆分任务无输入内容时 失焦自动删除
    const blurDelete = (index) => {
      const params = addTaskList.value[index];
      const showLength = Object.keys(params).filter((item) => params[item] && params[item][0]);
      console.log(params, showLength, '>>>');
      if (showLength.length === 0) {
        addTaskList.value.splice(index, 1);
      }
    };

    // 选中部门动态加载
    const loadData = (selectedOptions) => {
      console.log(selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      depList({id: targetOption.value, userid: userInfo.userid}).then((res) => {
        if (res.data.code === 200) {
          const {data} = res.data;
          if (data.deptUserList.length === 0 && data.kvDeptList.length === 0) {
            message.warning('当前选中部门暂无执行人');
          }
          const userList = data.deptUserList.map((item) => ({
            value: item.userid,
            label: item.name,
            parentid: targetOption.value,
          }));

          const depArr = data.kvDeptList.map((item) => ({
            value: item.id,
            label: item.name,
            isLeaf: false,
            parentid: item.parentid,
          }));
          targetOption.loading = false;
          targetOption.children = [...depArr, ...userList];
          departmentList.value = [...departmentList.value];
        }
      });
    };

    // 时间过滤
    const disabledDate = (current) => {
      const startTime = dayjs(props.itemInfo.taskCycleStart).startOf('day').unix();
      const endTime = dayjs(props.itemInfo.taskCycleEnd).endOf('day').unix();
      const currentTime = current.endOf('day').format('X');
      return currentTime && (currentTime < startTime || currentTime > endTime);
    };

    // 选择时间
    const onChangeDate = (e, r) => {
      console.log(r);
    };

    // 选择执行人
    const onChange = (index, v) => {
      console.log(v)
      addTaskList.value[index].selectOperator = v;
    };

    const value = ref();
    const treeData = ref([
    ]);
    watch(value, () => {
      console.log(value.value);
    });

    const filterTree = (children,parentid, key) => {
      return children.map((item) => {
        return ({
          key: item.id || item.userid,
          id: item.id || item.userid,
          ...item,
          value: JSON.stringify({...item, parentid}),
          // slots: {
          //   title: item,
          //   icon: item,
          // },
          title: item[key],
        })
      });
    };
    // 动态加载树形数据
    const onLoadData = (treeNode) => {
      return new Promise((resolve) => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }
        depList({
          id: treeNode.dataRef.id,
          userid: userInfo.userid,
        }).then((response) => {
          const {deptUserList, kvDeptList} = response.data.data;
          let users = filterTree(deptUserList.map((item) => ({...item, isLeaf: true})),treeNode.dataRef.id , 'name')
          let kvDeps = filterTree(kvDeptList.map((item) => ({...item, checkable: false})), treeNode.dataRef.id ,'name')
          treeNode.dataRef.children = [...users, ...kvDeps]
          treeData.value = [...treeData.value];
          console.log(treeData.value);
          resolve();
        });
      });
    };

    const treeOnChange = (value, label, extra) => {
      console.log(value, label, extra)
    }

    // 保存
    const saveSplitd = (index) => {
      const params = addTaskList.value[index];

      addTaskList.value[index].loading = true;
      console.log(params);
      if (!params.taskDescription) {
        message.error('请输入任务描述');
        addTaskList.value[index].loading = false;
        return;
      }
      if (!params.taskCycle) {
        message.error('请选择时间');
        addTaskList.value[index].loading = false;
        return;
      }
      if (!value.value.length) {
        message.error('请选择执行人');
        addTaskList.value[index].loading = false;
        return;
      }
      // const item = params.selectOperator[params.selectOperator.length - 1];
      const activeList = value.value.map((val) =>{
        const data = JSON.parse(val)
        return {
          performanceTaskBookId: performanceTaskBook.value.timeSelect.id,
          superiorPerformanceTaskId: props.itemInfo.id,
          taskDescription: params.taskDescription,
          taskCycleStart: params.taskCycle[0],
          taskCycleEnd: params.taskCycle[1],
          publisher: userInfo.name,
          publisherId: userInfo.userid,
          departmentId: data.parentid || taskItemInfo.value.departmentId,
          operator: data.name,
          operatorId: data.userid,
          taskState: data.userid === userInfo.userid ? '1' : '0',
        }
      })
      console.log(activeList)
      taskBreakUp(activeList).then((res) => {
        addTaskList.value[index].loading = false;
        if (res.data.code === 200) {
          message.success('保存成功');
          addTaskList.value.splice(index, 1);
          value.value = []
          emit('addItem', res.data.data);
        } else {
          message.error(res.data.msg);
        }
      }).catch(() => {
        addTaskList.value[index].loading = false;
      });
    };

    // 进度记录失焦 提交
    const onBlurEdit = () => {
      editRecord({
        progressRecord: pr.value,
        id: props.itemInfo.id,
      }).then((res) => {
        console.log(res);
      });
    };
    return {
      addTaskList,
      blurDelete,
      changeAdd,
      loadData,
      onChangeDate,
      onChange,
      saveSplitd,
      departmentList,
      pr,
      onBlurEdit,
      disabledDate,

      value,
      treeData,
      onLoadData,
      treeOnChange
    };
  },
});
</script>

<style lang="less" scoped>
.add-li {
  display: flex;
  //width: 95.3%;
  flex: 1;
  justify-content: space-between;
  height: 54px;
  padding-right: 26px;
  //padding-left: 58px;
  margin-left: 58px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  .spilt-lileft {
    display: flex;
    align-items: center;

    .handleColor {
      width: 8px;
      height: 8px;
      background: #49aa19;
      border-radius: 50%;
    }

    .spilt-ipt {
      width: 500px;
      resize: none;
      border: none;
      outline: none;
      box-shadow: none
    }
  }

  .spilt-liright {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 631px;

  }

  .task-pick {
    width: 234px;
  }

}

.spilt-div {
  width: 99%;
  height: 54px;
  //margin: 0 auto;
  padding-left: 58px;
  line-height: 54px;
  cursor: pointer;

  .border-bottom {
    display: flex;
    flex: 1;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);

    p {
      margin-left: 10px
    }
  }
}

.record {
  display: flex;
  align-items: flex-start;
  width: 99%;
  padding: 10px 0;
  margin: 0 auto;

  img {
    margin: 4px 0 0 24px;
  }

  .ipt {
    height: 20px;
    resize: none;
    border: none;
    outline: none;
    box-shadow: none;
  }

}

</style>
