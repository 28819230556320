<template>
  <a-form
      ref="ruleForm"
      v-bind="formItemLayout"
      :label-col="labelCol"
      :layout="layout"
      :model="form"
      :rules="rules"
      :wrapper-col="wrapperCol"
      class="mingcheng"
  >
    <a-form-item label="名称" name="name" required>
      <a-input v-model:value="form.name" placeholder="绩效任务本名称"/>
    </a-form-item>
    <a-form-item label="时间周期" name="date1">
      <a-range-picker
          v-model:value="form.date1"
          :placeholder="['开始时间', '结束时间']"
      />
    </a-form-item>
    <a-form-item label="主旨" name="keynote">
      <a-textarea
          v-model:value="form.keynote"
          class="end-ipt"
          placeholder="请输入主旨"
      />
    </a-form-item>
    <a-button
        v-if="layout === 'vertical'"
        class="createBtn"
        type="primary"
        @click="Gohome">创建
    </a-button>
  </a-form>
</template>

<script>
import {defineComponent} from 'vue';
import {AddTaskBook} from '@/api/task';

export default defineComponent({
  name: 'AddTask',
  components: {},
  props: {
    layout: {
      type: String,
      default: 'vertical',
    },
  },
  data() {
    return {
      // labelCol: {span: 4},
      wrapperCol: {span: 24},
      form: {
        name: '',
        date1: undefined,
        keynote: '',
      },
      rules: {
        name: [
          {required: true, message: '请输入绩效任务本', trigger: 'blur'},
        ],
        date1: [
          {
            required: true, message: '请选择日期', trigger: 'change', type: 'array',
          },
        ],
      },
    };
  },
  setup() {
  },
  computed: {
    formItemLayout() {
      const {layout} = this;
      return layout === 'horizontal'
          ? {
            labelCol: {span: 4},
            wrapperCol: {span: 14},
          }
          : {};
    },
  },
  methods: {
    Gohome() {
      this.$refs.ruleForm
      .validate()
      .then(() => {
        AddTaskBook({
          performanceTaskBookName: this.form.name,
          keynote: this.form.keynote,
          timeCycleStart: this.form.date1[0].format('YYYY-MM-DD'),
          timeCycleEnd: this.form.date1[1].format('YYYY-MM-DD'),
        }).then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.$router.push('/');
          }
        });
        console.log('values', this.form);
      })
      .catch((error) => {
        console.log('error', error);
      });
    },
    submit(callback) {
      this.$refs.ruleForm
      .validate()
      .then(() => {
        if (callback) {
          console.log(this.form);
          callback(this.form);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
  },
});
</script>

<style lang="less" scoped>
.mingcheng {
  .end-ipt {
    height: 76px;
    margin: 0;
    text-align: left;
    resize: none;

    input, button, select, textarea {
      outline: none
    }
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }

  .createBtn {
    width: 280px;
    height: 28px;
    margin-top: 56px;
    background: #1890ff;
    border-radius: 4px;
  }
}
</style>
