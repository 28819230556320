<template>
  <a-col v-if="info" :span="24" class="margin-top margin-bottom-40">
    <a-col class="bg-white" style="border-radius: 10px 0 10px 10px;">
      <p class="Times">{{ taskInfo.publishTime }}</p>
      <div class="Top-task  flex align-center justify-between">
        <div class="title-left">
          <p class="flex align-center">
            <img :src="imgSrc"/>
<!--            <span class="text-hidden flex" style="width:600px; max-width: 600px">-->
<!--               {{ taskInfo.taskDescription }}-->
<!--            </span>-->
            <a-tooltip>
              <template #title>
                {{ taskInfo.taskDescription }}
              </template>
              <div class="margin-left text-hidden" style="width: 100%">
                <span
                    class="text-hidden flex" style="width:500px; max-width: 500px">
                   {{ taskInfo.taskDescription }}
                </span>
              </div>
            </a-tooltip>
          </p>
        </div>
        <div class="title-right">
          <img v-if="taskInfo.overdue" class="padding-lr-xs" src="@/assets/yuqi.svg"/>
          <div>
            <a-popover :title="titlie" trigger="click">
               <img class="margin-left cursor-pt"  @click="recordTaskItem(item,index)" src="@/assets/recordTaskItem.svg"/>
             </a-popover>
          </div>
          <a-spin :spinning="treeLoading" size="small">
            <img
                class="cursor-pt padding-lr-xs"
                src="@/assets/tree.svg"
                @click.stop="changeOpenTreeModal(item)"
            />
          </a-spin>
          <a-tag :color="tagColorTypes[taskInfo.taskState.toString()]['type']">
            {{ tagColorTypes[taskInfo.taskState.toString()]['title'] }}
          </a-tag>

          <div class="task-timer">
            <span v-if="taskInfo.taskChildLIst.length || taskInfo.operatorId !== taskInfo.publisherId">
              {{ taskInfo.taskCycleStart }}
              <span style="padding: 0 1px 0 1px">~</span>
              {{ taskInfo.taskCycleEnd }}
            </span>
            <span v-else id="range-picker-change">
              {{ taskInfo.taskCycleStart }}
              <span style="padding: 0 1px 0 1px">~</span>
              {{ taskInfo.taskCycleEnd }}
            </span>
            <div class="change-picker" style="width: 200px;">
              <a-range-picker
                  v-model:value="selectTime"
                  :allowClear="false"
                  :border="false"
                  :placeholder="['开始时间', '结束时间']"
                  class="task-pick"
                  valueFormat="YYYY-MM-DD" @change="onChangeDate"/>
            </div>
          </div>

          <div class="task-start padding-right flex align-center">
            <img v-if="taskInfo.operatorId === taskInfo.publisherId" :src="imgSrcTwo" class="padding-lr-xs"/>
            <span v-if="taskInfo.operatorId === taskInfo.publisherId">发起 {{ taskInfo.departmentName }}/</span>
            <span v-else>
              {{ taskInfo.departmentName }}
              {{ taskInfo.publisher && taskInfo.operator ? '/' : '' }}
              {{ taskInfo.publisher }}
              <!--              {{ taskInfo.operator }}-->
            </span>
            <img v-if="taskInfo.operatorId !== taskInfo.publisherId" class="padding-lr-xs" src="@/assets/transmit.svg"/>
            {{ taskInfo?.operator }}
          </div>
          <!-- taskInfo.a 为1时展示完成按钮 -->
        </div>
      </div>
    </a-col>
  </a-col>
</template>

<script>
import {computed, defineComponent, inject, provide, ref, watch} from 'vue';
import {deleteTask, editState, editTask,progressRecord} from '@/api/task';
import {message} from 'ant-design-vue';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'TaskBlock',
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    imgSrc: {
      type: String,
    },
    imgSrcTwo: {
      type: String,
    },
  },
  setup(props) {
    const titlie = ref('');
    const info = ref(props.item);
    const userInfo = inject('userInfo');
    provide('taskItemInfo', info);
    const tagColorTypes = {
      0: {
        type: 'orange',
        title: '未读',
      },
      1: {
        type: 'blue',
        title: '待执行',
      },
      2: {
        type: 'red',
        title: '审核中',
      },
      3: {
        type: 'green',
        title: '已完成',
      },
    };
    watch(
        () => props.item,
        (n) => {
          info.value = n;
        },
    );
    // 改变状态
    const changeState = (taskState) => {
      editState({id: info.value.id, taskState})
      .then((res) => {
        if (res.data.code === 200) {
          info.value.taskState = taskState;
          if (taskState.toString() === '2') {
            info.value.a = 0; // 子任务是否全部完成
          }
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        message.error(err);
      });
    };
    // 删除任务
    const deleteTaskItem = async (item) => {
      try {
        const res = await deleteTask({id: item.id});
        if (res.data.code === 200) {
          info.value = null;
        } else {
          message.error(res.data.msg);
        }
      } catch (err) {
        message.error(err);
      }
    };
    // 逾期判断
    const taskInfo = computed(() => {
      const todayTime = info.value.completedTime ? new Date(info.value.completedTime).getTime() : Date.now();
      const endTime = dayjs(info.value.taskCycleEnd).endOf().valueOf()+86400000
      if (todayTime > endTime) {
        return {
          ...info.value,
          overdue: true,
        };
      }
      return {
        ...info.value,
        overdue: false,
      };
    });
    const selectTime = ref([taskInfo.value?.taskCycleStart, taskInfo.value?.taskCycleEnd]);
    const onChangeDate = (r) => {
      editTask({
        id: taskInfo.value.id,
        taskCycleStart: r[0],
        taskCycleEnd: r[1],
      }).then((res) => {
        if (res.data.code === 200) {
          info.value.taskCycleStart = r[0]
          info.value.taskCycleEnd = r[1]
        }
      })
    }
    const changeDep = (r) => {
      console.log(r)
      const {target: {value}} = r
      editTask({
        id: taskInfo.value.id,
        taskDescription: value
      }).then((res) => {
        if (res.data.code === 200) {
          info.value.taskDescription = value
        } else {
          message.error(res.data.msg)
        }
      })
    }
    //查看进度记录
   const recordTaskItem = async(item,index) => {
     try {
          const res = await progressRecord({id: item.id});
        if (res.data.code === 200) {
           titlie.value=res.data.data || '暂无进度记录'
        } else {
          message.error(res.data.msg);
        }
     } catch (err) {
         console.log(err);
     }
   }
    const {changeOpenTreeModal, treeLoading} = inject('changeOpenTreeModal');

    return {
      info,
      taskInfo,
      titlie,
      tagColorTypes,
      changeState,
      userInfo,
      deleteTaskItem,
       recordTaskItem,
      changeOpenTreeModal,
      treeLoading,
      selectTime,
      onChangeDate,
      changeDep
    };
  },
});
</script>

<style lang="less" scoped>
.Times {
  position: absolute;
  top: -25px;
  right: 0;
  width: 84px;
  height: 25px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
}

.Top-task {
  display: flex;
  width: 99%;
  height: 54px;
  margin: 0 auto;

  // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  .title-left {
    //line-height: 54px;
    //width: 500px;
    flex: 1;
    //height: 22px;
    margin-left: 24px;

    span {
      display: block;
      width: 200px;
      margin-left: 20px;
      //height: 22px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .title-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 500px;
    padding-right: 20px;
    //position: absolute;
    //right: 25px;
    //top: 10px;
  }
}

.btn {
  min-width: 38px;
  //padding-right: 16px;
}

.task-timer {
  width: 200px;
  text-align: center;

  &:hover {
    #range-picker-change {
      display: none;

      & + .change-picker {
        display: block;
      }
    }
  }

  .change-picker {
    display: none;
  }
}

.no-border {
  width: 100%;
  border-color: #fff;
}

::v-deep(.ant-input) {
  &:focus, &:hover {
    border-color: #40a9ff;
  }
}
</style>
