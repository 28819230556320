import Axios from '../utils/request';

// 绩效任务 - 修改绩效任务数据
export function editTask(data) {
  return Axios.post('/performance/task/updateTaskRecord', data);
}

// 绩效任务 - 修改进度记录
export function editRecord(data) {
  return Axios.post('/performance/task/updateProgressRecord', data);
}

// 绩效任务 - 变更任务状态
export function editState(data) {
  return Axios.post('/performance/task/updateTaskState', data);
}

// 绩效任务 - 拒绝通过
export function refuseTask(data) {  
  return Axios.get('/performance/task/refuseTask', {params: data});
}

// 绩效任务 - 新增绩效任务书
export function AddTaskBook(data) {
  return Axios.post('/performance/taskbook/save', data);
}

// 绩效任务 - 添加绩效任务/拆分绩效任务
export function AddTask(data) {
  return Axios.post('/performance/task/save', data);
}

// 绩效任务 - 拆分绩效任务
export function taskBreakUp(data) {
  return Axios.post('/performance/task/breakUp', data);
}

// 绩效任务 - 删除绩效任务
export function deleteTask(data) {
  return Axios.get('/performance/task/deleteTask', {params: data});
}
// 绩效任务 - 查看进度记录
export function  progressRecord (data) {
  return Axios.get('/performance/task/selectProgress', {params: data});
}
// 绩效任务 - 更换任务书数据
export function ChangeTask(data) {
  return Axios.get('/performance/taskbook/changeTaskBook', {params: data});
}

// 绩效任务 - 登录时绩效任务的数据
export function getTask(data) {
  return Axios.post('/performance/taskbook/selectTaskBooks', data);
}

// 获取绩效任务书
// http://192.168.107.164:8086/performance/taskbook/selectTaskBooks
export function getTaskBooks(data) {
  return Axios.post('/performance/taskbook/selectAll', {params: data});
}

// 获取绩效任务树
// http://192.168.107.164:8086/performance/taskbook/selectTaskBooks
export function getTaskTrees(data) {
  return Axios.get('/performance/task/taskTree', {params: data});
}

// 获取绩效任务树
// http://192.168.107.164:8086/performance/taskbook/selectTaskBooks
export function selectLinkman(data) {
  return Axios.get('/performance/task/selectLinkman', {params: data});
}

// 获取文件
// http://192.168.107.164:8086/performance/taskbook/selectTaskBooks
export function getExport(data) {
  return Axios.get('/performance/progress/export', {
    params: data,
    responseType: 'arraybuffer'
  },);
}
// 获取oss
export function getOssCode(data) {
  return Axios.post('/performance/oss/getOssCode', data);
}
//获取文件服务器地址
export function ossUpload(data) {
  return Axios.post('/performance/oss/upload', {params: data});
}
