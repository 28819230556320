<template>
  <a-col class="taskChildLIst bg-white" tag="ul">
    <a-col
      v-for="(item, index) in taskList"
      v-bind:key="index"
      class="taskChildLIst-li padding-right-xl"
      tag="li"
    >
      <div class="taskChildLIst-left">
        <p class="handleColor"></p>
        <p class="renwu">
          <a-tooltip>
            <template #title>
              {{ item.taskDescription }}
            </template>
            <div class="margin-left text-hidden" style="width: 100%">
              {{
                item.taskState == 3 ||
                item.taskState == 2 ||
                item.publisherId != userid
                  ? item.taskDescription
                  : ""
              }}
              <a-input
                v-if="
                  item.taskState != 3 &&
                  item.taskState !== 2 &&
                  item.publisherId == userid
                "
                :defaultValue="item.taskDescription"
                class="no-border"
                @blur="changeDeps"
                @change="changexg(item.id)"
              />
            </div>
          </a-tooltip>
        </p>
      </div>
      <div class="taskChildLIst-right">
        <img v-if="item.overdue" src="../../../../assets/yuqi.svg" />

        <div>
          <a-popover :title="titlie" trigger="click">
            <img
              class="margin-left cursor-pt"
              @click="recordTaskItem(item, index)"
              src="../../../../assets/recordTaskItem.svg"
            />
          </a-popover>
        </div>
        <a-spin :spinning="treeLoading" size="small">
          <img
            class="cursor-pt"
            src="../../../../assets/tree.svg"
            @click.stop="changeOpenTreeModal(item)"
          />
        </a-spin>

        <a-tag
          :color="tagColorTypes[item.taskState]['type']"
          @click="particulars"
        >
          {{ tagColorTypes[item.taskState]["title"] }}
        </a-tag>
        <a-modal
          :visible="visible4"
          title="查看附件："
          cancel-text="关闭"
          @cancel="
            (e) => {
              visible4 = false;
            }
          "
        >
          <div v-if="item.fileUrl">
            <div>附件（点击文件名下载）:</div>
            <div v-for="(list, key) in item.fileUrl" v-bind:key="key">
              <p @click="()=>{download(list , key)}" style="cursor:pointer;">{{ list }}</p>
            </div>
          </div>
            <div v-if="item.reason" style="margin-top:20px">
                拒绝原因：
                <p>{{item.reason}}</p>
              </div>
              <div v-if="item.finishReason" style="margin-top:20px">
                通过原因：
                <p>{{item.finishReason}}</p>
              </div>
        </a-modal>
        <div class="task-timer">
          {{ item.taskCycleStart }}~{{ item.taskCycleEnd }}
        </div>
        <div class="task-start">
          <img src="../../../../assets/check.svg" />
          {{ item.departmentName }}/{{ item.operator }}
        </div>
        <div class="btn text-right">
          <a-space :size="4">
            <template v-if="item.taskState.toString() === '2'">
              <!-- <a-popconfirm
                  cancel-text="取消"
                  ok-text="确认"
                  placement="topRight"
                  title="是否拒绝该任务通过?"
                  @confirm="refuseTaskState(item, index)"
              >
                <a-button
                    :loading="loading"
                    size="small"
                >拒绝
                </a-button>
              </a-popconfirm> -->
              <a-button :loading="loading" size="small" @click="showModal"
                >拒绝
              </a-button>
              <a-modal
                :visible="visible"
                title="拒绝"
                ok-text="确认"
                cancel-text="取消"
                @cancel="close"
                @ok="refuseTaskState(item, index)" 
              >
                <a-textarea
                  placeholder="请输入拒绝理由"
                  auto-size
                  :value="turnDown"
                  @change="reason"
                />
              </a-modal>
            </template>
            <template v-if="item.taskState.toString() === '2'">
              <a-popconfirm
                  cancel-text="取消"
                  ok-text="确认"
                  placement="topRight"
                  title="该任务是否确认通过?"
                  @confirm="changeState(item, index)"
              >
                <a-button
                    :loading="loading"
                    size="small"
                >通过
                </a-button>
              </a-popconfirm>
              <!-- <a-button :loading="loading" size="small" @click="taskBy"
                >通过
              </a-button>
              <a-modal
                :visible="visible2"
                title="通过"
                ok-text="确认"
                cancel-text="取消"
                @cancel="close"
                @ok="changeState(item, index)"
              >
                <a-textarea
                  placeholder="请输入通过理由"
                  auto-size
                  :value="passValue"
                  @change="pass"
                />
              </a-modal> -->
            </template>
          </a-space>
          <template
            v-if="
              item.taskState.toString() == '1' &&
              item.operatorId === item.publisherId
            "
          >
           <a-popconfirm
                cancel-text="取消"
                ok-text="确认"
                placement="topRight"
                title="是否确认完成该任务?"
                @confirm="changeState(item, index)"
            >
              <a-button

                  :loading="loading"
                  size="small"
                  @click="changeState(item, index)"
              >
                完成
              </a-button>
            </a-popconfirm>
          </template>
          <template
            v-if="Number(item.taskState) !== 3 && item.publisherId == userid"
          >
            <a-popconfirm
              cancel-text="取消"
              ok-text="确认"
              placement="topRight"
              title="是否确认删除该任务?"
              @confirm="deleteTaskItem(item, index)"
            >
              <img
                class="margin-left cursor-pt"
                src="../../../../assets/deleteTask.svg"
              />
            </a-popconfirm>
          </template>
        </div>
      </div>
    </a-col>

    <AddItem :itemInfo="itemInfo" @addItem="addItem" />
  </a-col>
</template>

<script>
import { computed, defineComponent, inject, ref } from "vue";
import AddItem from "@/components/TaskList/components/TaskBlock/AddItem.vue";
import {
  deleteTask,
  editState,
  refuseTask,
  progressRecord,
  editTask,
} from "@/api/task";
import { message } from "ant-design-vue";
import dayjs from "dayjs";
// import moment from 'moment';

export default defineComponent({
  name: "TaskItem",
  components: { AddItem },
  props: {
    itemInfo: {
      type: Object,
    },
    info: {
      type: Object,
    },
  },
  setup(props) {
    const changeTaskGet = inject("changeTaskGet");
    const userid = sessionStorage.getItem("UserId");
    // 任务列表
    const list = ref(props.info.taskChildLIst);
    const loading = ref(false);
    const titlie = ref("");
    const id = ref("");
    const visible = ref(false);
    const visible2 = ref(false);
    const visible3 = ref(false);
    const visible4 = ref(false);
    const turnDown = ref("");
    // 任务状态
    const tagColorTypes = {
      0: {
        type: "orange",
        title: "未读",
      },
      1: {
        type: "blue",
        title: "执行中",
      },
      2: {
        type: "red",
        title: "待核查",
      },
      3: {
        type: "green",
        title: "已完成",
      },
    };
// 下载
 const download = (list, key) => {
       const link = document.createElement('a')
      link.style.display = 'none'
      link.href = list
      link.download = link.href
      link.click()
 }
    // 点击通过任务
    const changeState = (item, index, state = "3") => {
      loading.value = true;
      editState({
        id: item.id,
        taskState: state
      })
        .then((res) => {
          loading.value = false;
          if (res.data.code === 200) {
            list.value[index].taskState = state;
            visible2.value = false
            visible3.value = false
            changeTaskGet();
            // message.success(res.data.msg);
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((err) => {
          loading.value = false;
          message.error(err);
          console.log("子组件调用");
        });
    };
    // 点击拒绝任务
    const refuseTaskState = (item, index) => {
      if (!turnDown.value) {
        return message.error("拒绝内容必填！");
      }
      loading.value = true;
      refuseTask({
        id: item.id,
        reason: turnDown.value,
      })
        .then((res) => {
          loading.value = false;
          if (res.data.code === 200) {
            console.log("子组件调用2");
            list.value[index].taskState = 1;
            visible.value = false;
            changeTaskGet();
            message.success(res.data.msg);
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((err) => {
          loading.value = false;
          message.error(err);
        });
    };
    const taskList = computed(() => {
      const arr = list.value.map((item) => {
        const todayTime = item.completedTime
          ? new Date(item.completedTime).getTime()
          : Date.now();
        const endTime = dayjs(item.taskCycleEnd).endOf().valueOf() + 86400000;
        console.log(item);
        if (typeof item.fileUrl == "string") {
          const arry = item.fileUrl?.split(",");
          item.fileUrl = arry;
        }

        if (todayTime >= endTime) {
          return {
            ...item,
            overdue: true,
          };
        }
        return {
          ...item,
          overdue: false,
        };
      });
      console.log(arr, 233);
      return arr;
    });
    // 拆分任务点击保存后 push
    const addItem = (item) => {
      item.forEach((v) => {
        list.value.push(v);
      });
      console.log(taskList);
    };

    const changexg = (e) => {
      console.log(e);
      if (e) {
        id.value = e;
      }
    };
    //修改任务单
    const changeDeps = (r) => {
      console.log(id);
      console.log(r);
      const {
        target: { value },
      } = r;
      editTask({
        id: id.value,
        taskDescription: value,
      }).then((res) => {
        if (res.data.code === 200) {
          message.success("修改成功");
          taskList.value.taskDescription = value;
        } else {
          message.error(res.data.msg);
        }
      });
    };
    // 删除任务
    const deleteTaskItem = async (item, index) => {
      try {
        const res = await deleteTask({ id: item.id });
        if (res.data.code === 200) {
          list.value.splice(index, 1);
        } else {
          message.error(res.data.msg);
        }
      } catch (err) {
        message.error(err);
      }
    };
    //查看进度记录
    const recordTaskItem = async (item, index) => {
      console.log(list);
      try {
        const res = await progressRecord({ id: item.id });
        if (res.data.code === 200) {
          titlie.value = res.data.data || "暂无进度记录";
        } else {
          message.error(res.data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    };
    // 拒绝理由
    const reason = (e) => {
      if (e) {
        turnDown.value = e.target.value;
      }
    };
    // 拒绝任务
    const showModal = () => {
      visible.value = true;
    };
    // 通过任务
    const taskBy = () => {
      visible2.value = true;
    };
    // 完成任务
    const accomplish = () => {
      visible3.value = true;
    };
    //关闭弹窗
    const close = () => {
      turnDown.value = "";
      visible.value = false;
      visible2.value = false;
      visible3.value = false;
    };
    // 审核详情
    const particulars = () => {
      visible4.value = true;
    };
    const { changeOpenTreeModal, treeLoading } = inject("changeOpenTreeModal");

    return {
      id,
      userid,
      visible,
      visible2,
      visible3,
      visible4,
      taskBy,
      turnDown,
      taskList,
      loading,
      titlie,
      addItem,
      changexg,
      changeState,
      changeDeps,
      refuseTaskState,
      tagColorTypes,
      deleteTaskItem,
      recordTaskItem,
      treeLoading,
      changeOpenTreeModal,
      showModal,
      reason,
      close,
      accomplish,
      particulars,
      download
    };
  },
});
</script>

<style lang="less" scoped>
.taskChildLIst {
  .taskChildLIst-li {
    display: flex;
    justify-content: space-between;
    //width: 95.3%;
    height: 54px;
    padding-left: 58px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    .taskChildLIst-left {
      display: flex;
      height: 52px;

      .renwu {
        height: 52px;
        line-height: 52px;
        border-bottom: 0;
      }

      .handleColor {
        width: 8px;
        height: 8px;
        margin-top: 22px;
        margin-right: 10px;
        background: #49aa19;
        border-bottom: 0;
        border-radius: 50%;
      }
    }

    .taskChildLIst-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 600px;
      height: 52px;
    }
  }
  .ant-popover-title {
    min-height: 48px;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .btn {
    min-width: 50px;

    .ant-btn {
      color: #40a9ff;
    }
  }
  .no-border {
    border-color: #fff;
    width: 100%;
  }
}
</style>
