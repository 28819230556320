<template>
  <a-col v-if="info" :span="24" class="margin-top margin-bottom-40">
    <a-col class="bg-white">
      <p class="Times">{{ taskInfo.publishTime }}</p>
      <div class="Top-task flex align-center justify-between">
        <div class="title-left">
          <p class="flex align-center">
            <img :src="imgSrc" />
            <!--            <span class="text-hidden flex" style="width:600px; max-width: 600px">-->
            <!--               {{ taskInfo?.taskDescription }}-->
            <!--            </span>-->
            <a-tooltip>
              <template #title>
                <!-- {{ taskInfo.taskDescription }} -->
              </template>
              <div class="margin-left text-hidden" style="width: 100%">
                {{
                  taskInfo.taskState == 3 ||
                  taskInfo.operatorId !== taskInfo.publisherId
                    ? taskInfo.taskDescription
                    : ""
                }}
                <a-input
                  v-if="
                    taskInfo.operatorId === taskInfo.publisherId &&
                    taskInfo.taskState !== 3
                  "
                  :defaultValue="taskInfo.taskDescription"
                  class="no-border"
                  @blur="changeDep"
                />
              </div>
            </a-tooltip>
          </p>
        </div>
        <div class="title-right">
          <img
            v-if="taskInfo.overdue"
            class="padding-lr-xs"
            src="../../../../assets/yuqi.svg"
          />
          <a-spin :spinning="treeLoading" size="small">
            <img
              class="cursor-pt padding-lr-xs"
              src="../../../../assets/tree.svg"
              @click.stop="changeOpenTreeModal(item)"
            />
          </a-spin>

          <a-tag
            :color="tagColorTypes[taskInfo.taskState.toString()]['type']"
            @click="particulars"
          >
            {{ tagColorTypes[taskInfo.taskState.toString()]["title"] }}
          </a-tag>
          <a-modal
            :visible="visible"
            title="通过的简述："
            cancel-text="关闭"
            @cancel="
              (e) => {
                visible = false;
              }
            "
          >
            <div v-if="taskInfo.fileUrl !== []">
              <div>附件（点击文件名下载）:</div>
              <div v-for="(list, key) in taskInfo.fileUrl" v-bind:key="key">
                <p @click="() => {download(list, key);}" style="cursor:pointer;">
                  {{ list }}
                </p>
              </div>
            </div>
            <div v-if="taskInfo.reason" style="margin-top: 20px">
              拒绝原因：
              <p>{{ taskInfo.reason }}</p>
            </div>
            <div v-if="taskInfo.finishReason" style="margin-top: 20px">
              完成详情：
              <p>{{ taskInfo.finishReason }}</p>
            </div>
          </a-modal>
          <div class="task-timer">
            <span
              v-if="
                taskInfo.taskChildLIst?.length ||
                taskInfo.operatorId !== taskInfo.publisherId
              "
            >
              {{ taskInfo.taskCycleStart }}
              <span style="padding: 0 1px 0 1px">~</span>
              {{ taskInfo.taskCycleEnd }}
            </span>
            <span v-else id="range-picker-change">
              {{ taskInfo.taskCycleStart }}
              <span style="padding: 0 1px 0 1px">~</span>
              {{ taskInfo.taskCycleEnd }}
            </span>
            <div class="change-picker" style="width: 200px">
              <a-range-picker
                v-model:value="selectTime"
                :allowClear="false"
                :border="false"
                :placeholder="['开始时间', '结束时间']"
                class="task-pick"
                valueFormat="YYYY-MM-DD"
                @change="onChangeDate"
              />
            </div>
          </div>
          <div class="task-start padding-right flex align-center">
            <img
              v-if="taskInfo.operatorId === taskInfo.publisherId"
              :src="imgSrcTwo"
              class="padding-lr-xs"
            />
            <span v-if="taskInfo.operatorId === taskInfo.publisherId"
              >发起 {{ taskInfo.departmentName }}/</span
            >
            <span v-else>
              {{ taskInfo.departmentName }}
              {{ taskInfo.publisher && taskInfo.operator ? "/" : "" }}
              {{ taskInfo.publisher }}
              <!--              {{ taskInfo.operator }}-->
            </span>
            <img
              v-if="taskInfo.operatorId !== taskInfo.publisherId"
              class="padding-lr-xs"
              src="@/assets/transmit.svg"
            />
            {{ taskInfo?.operator }}
          </div>
          <!-- taskInfo.a 为1时展示完成按钮 -->
          <div class="btn text-right">
            <template
              v-if="
                String(taskInfo.taskState) === '1' &&
                taskInfo.a === 1 &&
                taskInfo.operatorId === taskInfo.publisherId
              "
            >
              <a-popconfirm
                cancel-text="取消"
                ok-text=""
                placement="topRight"
                title="是否确认完成该任务?"
                @confirm="changeState('3')"
              >
                <a-button class="AgreeBtn" size="small" type="primary">
                  完成
                </a-button>
              </a-popconfirm>
            </template>
            <template
              v-else-if="String(taskInfo.taskState) === '1' && taskInfo.a === 1"
            >
              <a-popconfirm
                cancel-text="取消"
                ok-text=""
                placement="topRight"
                title="是否确认完成该任务?"
                @confirm="changeState('2')"
              >
                <a-button class="AgreeBtn" size="small" type="primary">
                  完成
                </a-button>
              </a-popconfirm>
            </template>
            <!-- <a-button
              v-if="taskInfo.taskState.toString() === '0' && taskInfo.a === 1"
              class="AgreeBtn"
              size="small"
              type="primary"
              @click="changeState('1')"
            >
              确认
            </a-button> -->
            <template
              v-if="
                (Number(taskInfo.taskState) === 0 &&
                  taskInfo.operatorId === taskInfo.publisherId) ||
                (Number(taskInfo.taskState) === 1 &&
                  taskInfo.operatorId === taskInfo.publisherId)
              "
            >
              <a-popconfirm
                cancel-text="取消"
                ok-text=""
                placement="topRight"
                title="是否确认删除该任务?"
                @confirm="deleteTaskItem(taskInfo)"
              >
                <img
                  class="margin-left cursor-pt"
                  src="../../../../assets/deleteTask.svg"
                />
              </a-popconfirm>
            </template>
          </div>
        </div>
      </div>
    </a-col>
    <TaskItem
      :info="taskInfo"
      :itemInfo="item"
      :tagColorTypes="tagColorTypes"
    />
  </a-col>
</template>

<script>
import { computed, defineComponent, inject, provide, ref, watch } from "vue";
import { deleteTask, editState, editTask } from "@/api/task";
import { message } from "ant-design-vue";
import TaskItem from "./TaskItem.vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "TaskBlock",
  components: { TaskItem },
  props: {
    item: {
      type: Object,
      required: true,
    },
    imgSrc: {
      type: String,
    },
    imgSrcTwo: {
      type: String,
    },
  },
  setup(props) {
    const info = ref(props.item);
    const changeTaskGet = inject("changeTaskGet");
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    const visible = ref(false);
    provide("taskItemInfo", info);
    const tagColorTypes = {
      0: {
        type: "orange",
        title: "未读",
      },
      1: {
        type: "blue",
        title: "待执行",
      },
      2: {
        type: "red",
        title: "审核中",
      },
      3: {
        type: "green",
        title: "已完成",
      },
    };
    watch(
      () => props.item,
      (n) => {
        info.value = n;
      }
    );
   const download = (list, key) => {
       const link = document.createElement('a')
      link.style.display = 'none'
      link.href = list
      link.download = link.href
      link.click()
 }
    // 改变状态
    const changeState = (taskState) => {
      editState({ id: info.value.id, taskState })
        .then((res) => {
          if (res.data.code === 200) {
            info.value.taskState = taskState;
            if (taskState.toString() === "2") {
              info.value.a = 0; // 子任务是否全部完成
            } else if (taskState.toString() === "3") {
              changeTaskGet();
            }
            message.success(res.data.msg);
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    };
    // 删除任务
    const deleteTaskItem = async (item) => {
      try {
        const res = await deleteTask({ id: item.id });
        if (res.data.code === 200) {
          info.value = null;
        } else {
          message.error(res.data.msg);
        }
      } catch (err) {
        message.error(err);
      }
    };
    // 逾期判断
    const taskInfo = computed(() => {
      const todayTime = info.value.completedTime
        ? new Date(info.value.completedTime).getTime()
        : Date.now();
      const endTime =
        dayjs(info.value.taskCycleEnd).endOf().valueOf() + 86400000;
      if (typeof info.value.fileUrl == "string") {
        const arry = info.value.fileUrl?.split(",");
        info.value.fileUrl = arry;
      }
      if (todayTime > endTime) {
        return {
          ...info.value,
          overdue: true,
        };
      }
      return {
        ...info.value,
        overdue: false,
      };
    });
    const selectTime = ref([
      taskInfo.value?.taskCycleStart,
      taskInfo.value?.taskCycleEnd,
    ]);
    const onChangeDate = (r) => {
      editTask({
        id: taskInfo.value.id,
        taskCycleStart: r[0],
        taskCycleEnd: r[1],
      }).then((res) => {
        if (res.data.code === 200) {
          info.value.taskCycleStart = r[0];
          info.value.taskCycleEnd = r[1];
        }
      });
    };
    const changeDep = (r) => {
      console.log(r);
      const {
        target: { value },
      } = r;
      editTask({
        id: taskInfo.value.id,
        taskDescription: value,
      }).then((res) => {
        if (res.data.code === 200) {
          info.value.taskDescription = value;
        } else {
          message.error(res.data.msg);
        }
      });
    };
    const particulars = () => {
      visible.value = true;
    };
    const { changeOpenTreeModal, treeLoading } = inject("changeOpenTreeModal");

    return {
      info,
      taskInfo,
      tagColorTypes,
      changeState,
      userInfo,
      deleteTaskItem,
      visible,
      changeOpenTreeModal,
      treeLoading,
      selectTime,
      onChangeDate,
      changeDep,
      particulars,
      download
    };
  },
});
</script>

<style lang="less" scoped>
.Times {
  position: absolute;
  top: -25px;
  right: 0;
  width: 84px;
  height: 25px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
}

.Top-task {
  display: flex;
  width: 99%;
  height: 54px;
  margin: 0 auto;

  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  .title-left {
    //line-height: 54px;
    //width: 500px;
    flex: 1;
    //height: 22px;
    margin-left: 24px;

    span {
      display: block;
      width: 200px;
      margin-left: 20px;
      //height: 22px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .title-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 500px;
    padding-right: 20px;
    //position: absolute;
    //right: 25px;
    //top: 10px;
  }
}

.btn {
  min-width: 38px;
  //padding-right: 16px;
}

.task-timer {
  width: 200px;
  text-align: center;

  &:hover {
    #range-picker-change {
      display: none;

      & + .change-picker {
        display: block;
      }
    }
  }

  .change-picker {
    display: none;
  }
}

.no-border {
  border-color: #fff;
  width: 100%;
}

::v-deep(.ant-input) {
  &:focus,
  &:hover {
    border-color: #40a9ff;
  }
}
</style>
