<!-- 通讯录 -->
<template>
  <a-drawer
      v-bind="$attrs"
  >
  <a-row class="padding-top-xl">
    <a-skeleton :loading="treeData.length <= 0" active>
      <a-input v-model:value="userName" placeholder="搜索姓名" @change="searchEvent">
        <template #suffix>
          <SearchOutlined/>
        </template>
      </a-input>
      <a-col span="24">
        <a-spin :spinning="userLoading">
          <a-col v-if="userName" class="padding-top-sm" span="24" tag="ul">
            <li v-for="(item) in userList" :key="item.userid" class="flex align-center justify-between padding-tb-sm">
              <div class="flex align-center">
                <a-avatar v-if="item.userid" :src="item.avatar" shape="square">
                  {{ item.name }}
                </a-avatar>
                <span class="padding-left">
                {{ item.name }}
              </span>
              </div>
              <a-button size="small" style=" color: rgba(24,144,255,1);border-radius: 4px" @click="goPath(item)">查看
              </a-button>
            </li>
            <a-result v-if="!userList.length" status="404" sub-title="未找到">
            </a-result>
          </a-col>
        </a-spin>
        <a-tree
            v-show="!userName"
            v-model:expandedKeys="expandedKeys"
            v-model:selectedKeys="selectedKeys"
            :blockNode="true"
            :load-data="onLoadData"
            :tree-data="treeData"
        >
          <template #title="{ slots }">
            <div class="flex justify-between align-center">
              <div>
                <a-avatar v-if="slots?.title?.userid" :src="slots?.title?.avatar" shape="square">
                  {{ slots?.title?.name }}
                </a-avatar>
                  {{ slots?.title?.name }}
              </div>
              <div v-if="slots?.title?.userid">
                <a-button
                    size="small"
                    style=" color: rgba(24,144,255,1);border-radius: 4px"
                    @click="goPath(slots?.title)"
                >
                  查看
                </a-button>
              </div>
            </div>
          </template>
        </a-tree>
      </a-col>
    </a-skeleton>
  </a-row>
  </a-drawer>
</template>
<script>
import {defineComponent, onBeforeMount, ref} from "vue";
import {useRouter} from 'vue-router';
import {SearchOutlined} from "@ant-design/icons-vue";
import {depListFilterSelf} from '@/api/dingApi';
import {useSearch} from '@/hooks/useSearch'

export default defineComponent({
  //import引入的组件需要注入到对象中才能使用
  components: {SearchOutlined},
  props: {
    addressVisible: Boolean
  },
  setup() {
    const router = useRouter()
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

    const expandedKeys = ref([]);
    const selectedKeys = ref([]);
    const treeData = ref([]);

    const userName = ref(null);
    const [userList, onChange, userLoading] = useSearch(userInfo)

    const searchEvent = (e) => {
      const {userid, department} = userInfo
      onChange({userid, name: userName.value, deptId: department[0]})
      console.log(userList)
    }

    /**
     *
     * @param children
     * @param key
     * @returns []
     */
    const filterTree = (children, key) => {
      console.log(key)
      return children.map((item) => {
        return ({
          key: item.id,
          ...item,
          slots: {
            title: item,
            icon: item,
          },
          title: item[key],
        })
      });
    };

    onBeforeMount(() => {
      // 默认加载第一级
      depListFilterSelf({
        id: userInfo.department[0],
        userid: userInfo.userid,
        peers: 1,
      }).then((response) => {
        console.log(response)
        const {deptUserList, kvDeptList} = response.data.data;
        let users = filterTree(deptUserList.map((item) => ({...item, isLeaf: true})), 'name')
        let kvDeps = filterTree(kvDeptList, 'name')
        treeData.value = [...users, ...kvDeps]
      });
    })

    // 动态加载树形数据
    const onLoadData = (treeNode) => {
      return new Promise((resolve) => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }
        depListFilterSelf({
          id: treeNode.dataRef.id,
          userid: userInfo.userid,
        }).then((response) => {
          console.log(response)
          const {deptUserList, kvDeptList} = response.data.data;
          let users = filterTree(deptUserList.map((item) => ({
            ...item,
            department: [treeNode.dataRef.id],
            isLeaf: true
          })), 'name')
          let kvDeps = filterTree(kvDeptList, 'name')
          treeNode.dataRef.children = [...users, ...kvDeps]
          treeData.value = [...treeData.value];
          resolve();
        });
      });
    };

    const goPath = async (e) => {
      await router.replace({
        path: '/otherInfo/' + e?.userid??'',
        query: {
          ...e
        }
      })
      await router.go(0)
    }

    return {
      userInfo,
      userName,
      searchEvent,
      userList,

      expandedKeys,
      selectedKeys,
      treeData,
      onLoadData,
      goPath,
      userLoading
    };
  },
});
</script>

<style lang='less' scoped>
::v-deep(.ant-tree) {
  [role="treeitem"] {
    .ant-tree-node-content-wrapper {
      min-height: 32px;
      line-height: 28px;
    }
  }
}
</style>
