import {createRouter, createWebHistory} from 'vue-router';
import * as dd from 'dingtalk-jsapi';
import {dindinLogin} from '@/api/dingApi';
import Home from '@/views/Home/Home.vue';
import AddTask from '../views/AddTask.vue';
import CreateTask from '../views/CreateTask.vue';
import Kongbai from '../views/Kongbai.vue';
import OtherInfo from '@/views/otherInfo/index.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/otherInfo/:userid',
    name: 'OtherInfo',
    component: OtherInfo
    // component: () => import('@/views/LookOtherInfo/index.vue')
  },
  {
    path: '/addTask',
    name: 'AddTask',
    component: AddTask,
  },
  {
    path: '/createtask',
    name: 'CreateTask',
    component: CreateTask,
  },
  {
    path: '/kongbai',
    name: 'Kongbai',
    component: Kongbai,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const userInfo = sessionStorage.getItem('userInfo');

  if (!userInfo) {
    if (dd.env.platform !== 'notInDingTalk') { // 进行钉钉登录操作
      dd.ready(() => {
        // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
        dd.runtime.permission.requestAuthCode({
          corpId: process.env.NODE_ENV === 'production' ? 'dingd8291973cab81310ee0f45d8e4f7c288' : 'ding8e9662bf765c28c5',
          onSuccess(result) {
            sessionStorage.setItem('codes', result.code);
            const {code} = result;
            dindinLogin({
              code,
              // code: 'e0ca314ca93633f6b43753daf2ec9c4e',
            }).then((response) => {
              console.log(response);
              sessionStorage.setItem('UserId', response.data.data.userid);
              sessionStorage.setItem('userInfo', JSON.stringify(response.data.data));
              next();
            });
          },
          onFail() {
          },
        });
      });
    }
  } else {
    next();
  }
});

export default router;
