import Axios from '../utils/request';

// 获取部门列表
export function depList(data) {
  return Axios.get('/performance/enterprise/deptList', {params: data});
}

// 获取部门列表
export function depListFilterSelf(data) {
  return Axios.get('/performance/enterprise/deptListRight', {params: data});
}

// 获取钉钉用户列表
export function deptUserList(data) {
  return Axios.get('/performance/enterprise/deptUserList', data);
}

// 钉钉人员详情  可以做权限判断

export function dindinUserInfo(data) {
  return Axios.get('/performance/enterprise/queryUserInfo', {params: data});
}
 
// 钉钉通讯录 .钉钉登录
export function dindinLogin(data) {
  return Axios.get('/performance/enterprise/dindinLogin', {params: data});
}

// 通过userid获取部门的id
export function DepartmentId(data) {
  return Axios.get('/performance/enterprise/queryDeptListByUserid', {params: data});
}
