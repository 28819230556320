<template>
  <div class="home">
    <div class="header">
      <div class="header-main">
        <div class="header-left">
          <div class="portal">
            <img v-if="avatar" :src="avatar"/>
            <span v-else
                  class="bg-blues text-white text-lg flex align-center justify-center"
            >{{ name.substr(-2, 2) }}</span>
          </div>
          <div class="name">
            <p>
              {{ name }}
            </p>
          </div>
          <div class="achievement">
            <p>
              {{ purposevalue }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="box">
        <img src="../../src/assets/none.svg"/>
        <p>暂无绩效任务本 </p>
        <p>请联系管理层添加绩效任务本</p>
      </div>
    </div>
  </div>
</template>
<script>

import {ref} from 'vue';

export default {
  setup() {
    const purposevalue = ref('');
    const {name, avatar} = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo'));

    // 暴露给模板
    return {
      name,
      purposevalue,
      avatar,
    };
  },

};
</script>
<style lang="less" scoped>

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #F1F4F5;

  .header {
    width: 100%;
    height: 92px;
    background-color: #fff;

    .header-main {
      display: flex;
      width: 94%;
      height: 92px;
      margin: 0 auto;

      .header-left {
        display: flex;
        width: 50%;
        height: 92px;

        img {
          width: 52px;
          height: 52px;
          margin-top: 20px;
          border-radius: 8px;
        }

        span {
          display: block;
          width: 52px;
          height: 52px;
          margin-top: 20px;
          border-radius: 8px;
        }

        .name {
          margin-top: 32px;
          margin-left: 16px;
          font-size: 20px;
          font-weight: 500;

          color: rgba(0, 0, 0, 0.85);
        }

        .achievement {
          width: auto;
          height: 22px;
          margin-top: 35px;
          margin-left: 32px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .header-right {
        display: flex;
        justify-content: space-between;
        width: 50%;
        height: 100%;

        .task {
          display: flex;
          min-width: 538px;
          height: 60px;
          margin-top: 16px;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.08);
          border-radius: 8px;

          .left {
            position: relative;
            width: 58px;
            height: 58px;
            border-right: 1px solid rgba(0, 0, 0, 0.08);

            img {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }

          .right {
            position: relative;
            width: 58px;
            height: 58px;

            img {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }

          .time {
            display: flex;
            flex: 1;

            .timeLeft {
              width: 50%;
              height: 58px;
              border-right: 1px solid rgba(0, 0, 0, 0.08);
              border-left: 1px solid rgba(0, 0, 0, 0.08);

              p {
                text-align: center;
              }

              .titled1 {
                margin: 5px auto;
                font-size: 16px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
              }

              .calendar1 {
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
              }

            }

            .timeRight {
              width: 50%;
              height: 58px;
              color: rgba(0, 0, 0, 0.85);
              border-right: 1px solid rgba(0, 0, 0, 0.08);

              p {
                text-align: center;
              }

              .titled {
                margin: 5px auto;
                font-size: 16px;
                font-weight: 500;
              }

              .calendar {
                font-size: 14px;
                font-weight: 400;
              }

            }

            .bg-blue {
              color: #fff;
              background-color: #1890ff;

              .titled {
                margin: 5px auto;
                font-size: 16px;
                font-weight: 500;
              }

              .calendar {
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        .addBtn {
          width: 60px;
          height: 32px;
          margin-top: 30px;
          margin-right: 15px;
          background: #1890ff;
          border-radius: 4px;
        }
      }
    }
  }

  .section {
    display: flex;
    flex: 1;
    width: 94%;
    margin: 30px auto;

    .box {
      width: 400px;
      height: 593px;
      margin: 96px auto;
      text-align: center;

      p {
        width: 260px;
        margin: 10px auto;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.45);
        text-align: center;
      }
    }
  }
}
</style>
