<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view v-slot="{ Component }">
        <keep-alive include="Home">
          <component :is="Component"/>
        </keep-alive>
      </router-view>

    </div>
  </a-config-provider>
</template>
<script>
import {ref} from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

// 此方式为整体加载，也可按需进行加载
export default {
  name: 'App',
  setup() {
    const locale = ref(zhCN);
    return {
      locale,
    };
  },
  beforeRouteEnter(to, from) {
    console.log(to, from);
  },
};
</script>
<style lang="less">
</style>
