import axios from 'axios';
import {message} from 'ant-design-vue';
// 创建一个axios对象
const Axios = axios.create({
  // baseURL: process.env.NODE_ENV !== 'production' ? 'http://192.168.107.164:8088' : 'https://kpi.ytxlw.com',
  baseURL: process.env.NODE_ENV !== 'production' ? 'http://192.168.107.41:8088' : 'https://kpi.ytxlw.com',
  timeout: 60000, // request timeout
});
// 请求拦截
// 所有的网络请求都会先走这个方法，可以在里面添加自定义的内容
Axios.interceptors.request.use((config) => {
  const configs = config;
  configs.headers = { 'Content-Type': 'application/json' }
    if(configs.url==='/performance/oss/upload'){
        configs.headers = { 'Content-Type': 'multipart/form-data'}
    }
  return configs;
}, (err) => Promise.reject(err));

// 响应拦截
// 所有的网络请求返回数据都会先执行此方法。`
// 此处可以个根据状态码做相应的数据

Axios.interceptors.response.use((response) => {
    const {code} = response.data;
    if (code === 500) {
      message.error(response.data.msg);
      return Promise.reject(response.data);
    }
    return response;
  },
  (err) => Promise.reject(err));

export default Axios;
