import {getTask} from '@/api/task';
import {onBeforeMount, ref} from 'vue';
import {useRouter} from 'vue-router';

const useTimeSelect = (userData) => {
  const {replace} = useRouter();

  const userInfo = userData || sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo'));
  const name = ref(userInfo.name);
  const timeList = ref([]); // 所有时间列表
  const selectTimeList = ref([]); // 当前展示的两个时间选择
  const activeTime = ref(0); // 当前选中的时间下标
  const avatars = ref(userInfo.avatar);

  const getList = () => {
    getTask().then((res) => {
      if (res.data.code === 200) {
        // console.log(res);
        const {data} = res.data;

        // 判断用户是否拥有创建任务书权限
        if (userInfo.authTable === '1') {
          if (data.length === 0) {
            replace('/createtask');
            return;
          }
        } else if (userInfo.authTable === '0') {
          if (data.length === 0) {
            replace('/kongbai');
            return;
          }
        }

        data.forEach((item, index) => {
          // eslint-disable-next-line no-param-reassign
          item.index = index;
        });
        timeList.value = data;
        selectTimeList.value = data.filter((item, index) => index < 2);
        // activeTime.value = 0;
      }
    });
  };

  // 点击时间时切换index
  const changeTime = (index) => {
    activeTime.value = index;
  };

  // 左右切换点击事件
  const changeHandler = (lr) => {
    const {index} = selectTimeList.value[0];
    if (selectTimeList.value.length === 1) return;
    let left;
    let right;
    if (activeTime.value === 1 && !lr) {
      return;
    }
    if (!activeTime.value && !lr) {
      return;
    }
    // 减
    if (!lr) {
      if (timeList.value[index - 3]) {
        [left, right] = [timeList.value[index - 3], timeList.value[index - 2]];
        changeTime(index - 3);
      } else if (timeList.value[index - 2]) {
        [left, right] = [timeList.value[index - 2], timeList.value[index - 1]];
        changeTime(index - 2);
      } else if (timeList.value[index - 1]) {
        [left, right] = [timeList.value[index - 1], timeList.value[index]];
        changeTime(index - 1);
      }
      // 加
    } else {
      // eslint-disable-next-line no-lonely-if
      if (timeList.value[index + 3]) {
        [left, right] = [timeList.value[index + 2], timeList.value[index + 3]];
        changeTime(index + 2);
      } else if (timeList.value[index + 2]) {
        [left, right] = [timeList.value[index + 1], timeList.value[index + 2]];
        changeTime(index + 1);
      } else if (timeList.value[index + 1]) {
        [left, right] = [timeList.value[index], timeList.value[index + 1]];
        changeTime(index + 1);
      }
    }
    selectTimeList.value = [left, right];
  };

  onBeforeMount(() => {
    getList();
  });

  return {
    selectTimeList,
    changeTime,
    changeHandler,
    activeTime,
    name,
    timeList,
    avatars,
    getList,
  };
};

export default useTimeSelect;
