<template>
  <div class="home padding-bottom-lg">
    <div class="header">
      <div class="header-main">
        <div class="header-left">
          <div class="portal">
            <img v-if="avatars" :src="avatars" />
            <span
              v-else
              class="bg-blues text-white text-lg flex align-center justify-center"
              >{{ name.substr(-2, 2) }}</span
            >
          </div>
          <div class="name">
            <p>
              {{ name }}
            </p>
          </div>
          <div class="achievement">
            <p>
              {{ purposevalue }}
            </p>
          </div>
        </div>
        <div class="header-right">
          <div class="task">
            <div class="left cursor-pt" @click="changeHandler(0)">
              <img src="@/assets/left.svg" />
            </div>
            <div class="time">
              <template v-for="item in selectTimeList" :key="item.id">
                <div
                  :class="[activeTime === item.index ? 'bg-blue' : '']"
                  class="timeRight cursor-pt"
                  @click="changeTime(item.index)"
                >
                  <p class="titled">{{ item.performanceTaskBookName }}</p>
                  <p class="calendar">
                    {{ item.timeCycleStart }}~{{ item.timeCycleEnd }}
                  </p>
                </div>
              </template>
            </div>
            <div class="right cursor-pt" @click="changeHandler(1)">
              <img src="@/assets/right.svg" />
            </div>
          </div>
          <div class="flex align-center">
            <a-button
              v-if="userInfo.authTable === '1'"
              class="border-round-10 margin-right-lg"
              type="primary"
              @click="showModal"
            >
              新增
            </a-button>
            <img
              class="margin-left-lg cursor-pt"
              shape="square"
              src="@/assets/addressbook.png"
              style="width: 38px"
              @click="addressVisible = true"
            />
          </div>
          <a-modal
            v-model:visible="visible"
            :confirm-loading="confirmLoading"
            cancelText="取消"
            okText="确定"
            title="新增"
            @ok="handleOk"
          >
            <AddTaskForm ref="addForm" layout="horizontal" />
          </a-modal>
        </div>
      </div>
    </div>

    <div class="section">
      <task-list
        v-if="timeList.length > 0"
        ref="taskListDom2"
        :timeSelect="timeList[activeTime]"
      />
    </div>
    <div class="footer">
      <div class="footer-main">
        <div v-if="seeshow" class="footer-top">
          <i class="icon-task">
            <img src="@/assets/create.svg" />
          </i>
          <a-input
            v-model:value="taskDescription"
            class="task-ipt"
            placeholder="发起你的绩效任务"
          />
          <a-select
            placeholder="绩效任务所属部门"
            style="width: 214px; margin-right: 70px"
            @change="handleChange"
          >
            <a-select-option
              v-for="item in departmentName"
              :key="item.userid"
              :value="item"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-range-picker
            v-model="addTaskTime"
            :disabledDate="disabledDate"
            :placeholder="['开始时间', '结束时间']"
            class="task-pick"
            @calendarChange="panelChangeTime"
            @openChange="openChange"
            @change="handleTaskTime"
          />
          <a-button
            :loading="addLoading"
            class="task-btn"
            type="primary"
            @click="addTasks()"
            >保存
          </a-button>
        </div>
        <div class="footer-bottom" @click="openShow()">
          <p class="task-addBtn">
            <img src="@/assets/add.svg" /><span>添加绩效任务</span>
          </p>
        </div>
      </div>
    </div>
    <div class="section">
      <task-lists
        v-if="timeList.length > 0"
        ref="taskListDom"
        :timeSelect="timeList[activeTime]"
      />
    </div>

    <a-back-top />
    <a-drawer
      v-model:visible="addressVisible"
      :closable="false"
      :get-container="false"
      destroyOnClose
      placement="right"
      width="460"
    >
      <AddressBook @close="() => (addressVisible = false)" />
    </a-drawer>
  </div>
</template>
<script>
import { provide, ref, watch } from "vue";
import dayjs from "dayjs";
import { AddTask, AddTaskBook, getTaskBooks } from "@/api/task";
import { DepartmentId } from "@/api/dingApi";
import useTimeSelect from "@/hooks/useTimeSelect";
import { message } from "ant-design-vue";
import AddTaskForm from "@/views/AddTaskForm/AddTask.vue";
import TaskList from "../../components/TaskList/index.vue";
import TaskLists from "../../components/TaskListnu/index.vue";
import AddressBook from "./components/AddressBook";

export default {
  name: "Home",
  components: {
    AddTaskForm,
    // 'V-common': common,
    TaskList,
    TaskLists,
    AddressBook,
  },

  data() {
    return {
      visible: false,
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      namevalues: "", // 名称
      purposevalues: "", // 主旨
      seeshow: false,
      addLoading: false,
      taskCycleStart: "",
      taskDescription: "", // 任务描述
      taskCycleEnd: "",
      TaskBooks: {},
      departmentIded: "",
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 3,
            max: 5,
            message: "Length should be 3 to 5",
            trigger: "blur",
          },
        ],
        time: [
          {
            required: true,
            message: "请选择开始和结束时间",
          },
        ],
      },
    };
  },

  setup() {
    // 获取任务列表实例
    const taskListDom = ref(null);
    const taskListDom2 = ref(null);
    const userInfo =
      sessionStorage.getItem("userInfo") &&
      JSON.parse(sessionStorage.getItem("userInfo"));
    const namevalue = ref("");
    const lastnamevalue = ref("");
    const addTaskTime = ref([]);
    const timeCycleStart = ref(""); // 任务开始时间
    const timeCycleEnd = ref(""); // 任务结束时间
    const lastTimeCycleStart = ref(""); // 上一个任务开始时间
    const lastTimeCycleEnd = ref(""); // 上一个任务结束时间
    const departmentName = ref([]); // 部门的名称
    const userid = sessionStorage.getItem("UserId");
    const testId = ref("");
    const departmentId = ref("");

    // 时间选择抽离
    const {
      activeTime,
      timeList,
      selectTimeList,
      name,
      changeHandler,
      changeTime,
      avatars,
      getList,
    } = useTimeSelect();

    const purposevalue = ref(timeList?.value[0]?.keynote);
    
    const refreshCompleted = () => {
      taskListDom.value.changeTaskGet(timeList.value[activeTime.value].id);
    };

        // 向下级传递 获取任务列表事件
    provide('refreshCompleted',refreshCompleted);

    // 监听下标 变化
    watch(
      () => activeTime.value,
      (newVal) => {
        purposevalue.value = timeList.value[newVal].keynote;
        testId.value = timeList.value[activeTime.value].id;
      }
    );

    const disableTimeStartOrEnd = ref();
    const panelChangeTime = (e) => {
      disableTimeStartOrEnd.value = e[0]
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
    };
    // 日期区间 打开关闭事件
    const openChange = (e) => {
      disableTimeStartOrEnd.value = null;
    };

    // 时间过滤
    const disabledDate = (current) => {
      // console.log(current)
      const { timeCycleStart, timeCycleEnd } = timeList.value[activeTime.value];
      const startTime = dayjs(timeCycleStart).startOf("day").unix();
      const endTime = dayjs(timeCycleEnd).endOf("day").unix();
      const currentTime = current.endOf("day").format("X");
      const disableEndTime = dayjs(disableTimeStartOrEnd.value)
        .endOf("month")
        .endOf("day")
        .unix();
      if (disableTimeStartOrEnd.value)
        return (
          currentTime &&
          (currentTime < dayjs(disableTimeStartOrEnd.value).unix() ||
            currentTime > disableEndTime ||
            currentTime > endTime)
        );
      return currentTime && (currentTime < startTime || currentTime > endTime);
    };

    // 获取部门的信息
    DepartmentId({
      userid,
    }).then((res) => {
      departmentName.value = res.data.data;
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const id in res.data.data) {
        departmentId.value = res.data.data[id].id;
      }
    });
    // 通讯录抽屉
    const addressVisible = ref(false);

    // 暴露给模板
    return {
      taskListDom,
      taskListDom2,
      userInfo,
      name,
      namevalue,
      purposevalue,
      addTaskTime,
      timeCycleStart,
      timeCycleEnd,
      lastTimeCycleStart,
      lastTimeCycleEnd,
      lastnamevalue,
      avatars,
      selectTimeList,
      timeList,
      activeTime,
      changeTime,
      changeHandler,
      getList,
      departmentName,
      testId,
      departmentId,
      disableTimeStartOrEnd,
      panelChangeTime,
      disabledDate,
      openChange,
      addressVisible,
    };
  },

  methods: {
    showModal() {
      this.visible = true;
    },
    openShow() {
      this.seeshow = true;
    },
    handleChange(item) {
      // console.log(item);
      this.departmentIded = item.id;
    },
    handleTaskTime(d, date) {
      console.log(date);
      if (!date[0]) {
        this.disableTimeStartOrEnd = null;
      }
      this.addTaskTime = date;
    },
    // 任务保存
    addTasks() {
      console.log(this.timeList[this.activeTime].id);
      // this.seeshow = false;
      const userid = sessionStorage.getItem("UserId");
      if (!this.taskDescription) {
        message.error("任务描述不能为空！");
        return;
      }

      if (!this.departmentIded) {
        message.error("请选择部门");
        return;
      }

      if (!this.addTaskTime.length > 0) {
        message.error("请选择开始与结束时间！");
        return;
      }

      this.addLoading = true;

      AddTask({
        taskDescription: this.taskDescription,
        taskCycleStart: this.addTaskTime[0],
        taskCycleEnd: this.addTaskTime[1],
        publisher: this.name,
        publisherId: userid,
        performanceTaskBookId: this.timeList[this.activeTime].id,
        operator: this.name,
        operatorId: userid,
        departmentId: this.departmentIded,
        taskState: "1",
      })
        .then((res) => {
          this.addLoading = false;
          if (res.data.code !== 200) {
            this.seeshow = true;
          } else {
            this.taskDescription = null;
            this.addTaskTime = null;
            this.departmentIded = "";
            this.seeshow = false;
            this.$nextTick(() => {
              this.taskListDom.changeTaskGet(this.timeList[this.activeTime].id);
            });
            this.$nextTick(() => {
              this.taskListDom2.changeTaskGet(
                this.timeList[this.activeTime].id
              );
            });
          }
        })
        .catch(() => {
          this.addLoading = false;
        });
    },
    
    // 获取任务书时间
    onDate(date) {
      this.timeCycleStart = date[0].format("YYYY-MM-DD ");
      this.timeCycleEnd = date[1].format("YYYY-MM-DD ");
    },
    // modal框的确认按钮
    handleOk() {
      // this.$refs.ruleForm.validate().then(() => {
      //   console.log('value', this.form);
      // }).catch((error) => {
      //   console.log('error', error);
      // });
      this.$refs.addForm.submit((v) => {
        if (v) {
          if (v.date1.length !== 2) {
            message.error("请选择任务开始和结束日期");
            return;
          }
          this.confirmLoading = true;
          // 新建任务书
          const userid = sessionStorage.getItem("UserId");
          console.log(v.date1);
          AddTaskBook({
            performanceTaskBookName: v.name,
            keynote: v.keynote,
            timeCycleStart: v.date1[0] && v.date1[0]?.format("YYYY-MM-DD"),
            timeCycleEnd: v.date1[1] && v.date1[1]?.format("YYYY-MM-DD"),
            publisherId: userid,
            publisher: this.name,
          })
            .then((res) => {
              if (res.data.code !== 200) {
                this.confirmLoading = false;
              } else {
                this.visible = false;
                this.$refs.addForm.resetForm();
                getTaskBooks({
                  userid,
                }).then((ress) => {
                  if (ress.data.code === 200) {
                    this.confirmLoading = false;
                    this.getList();
                  } else {
                    this.confirmLoading = false;
                    message.error(ress.data.msg);
                  }
                });
              }
            })
            .catch((error) => {
              message.error(JSON.stringify(error));
              this.visible = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.end-ipt {
  height: 76px;

  input,
  button,
  select,
  textarea {
    outline: none;
  }
  resize: none;
}

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: #f1f4f5;

  .header {
    width: 100%;
    height: 92px;
    background-color: #fff;

    .header-main {
      display: flex;
      width: 94%;
      height: 92px;
      margin: 0 auto;

      .header-left {
        display: flex;
        width: 50%;
        height: 92px;

        .portal {
          width: 52px;
          height: 52px;

          margin-top: 20px;
          //background-color:#1890ff;
          //border-radius: 8px;
          img {
            width: 52px;
            height: 52px;
            border-radius: 8px;
          }

          span {
            display: block;
            width: 52px;
            height: 52px;
            border-radius: 8px;
          }
        }

        .name {
          margin-top: 32px;
          margin-left: 16px;
          font-size: 20px;
          font-weight: 500;

          color: rgba(0, 0, 0, 0.85);
        }

        .achievement {
          width: auto;
          height: 22px;
          margin-top: 35px;
          margin-left: 32px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .header-right {
        display: flex;
        justify-content: space-between;
        width: 50%;
        height: 100%;

        div,
        p,
        span {
          user-select: none;
        }

        .task {
          display: flex;
          min-width: 538px;
          height: 60px;
          margin-top: 16px;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.08);
          border-radius: 8px;

          .left {
            position: relative;
            width: 58px;
            height: 58px;
            border-right: 1px solid rgba(0, 0, 0, 0.08);

            img {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }

          .right {
            position: relative;
            width: 58px;
            height: 58px;

            img {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }

          .time {
            display: flex;
            flex: 1;

            .timeLeft {
              width: 50%;
              height: 58px;
              border-right: 1px solid rgba(0, 0, 0, 0.08);
              border-left: 1px solid rgba(0, 0, 0, 0.08);

              p {
                text-align: center;
              }

              .titled1 {
                margin: 5px auto;
                font-size: 16px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
              }

              .calendar1 {
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
              }
            }

            .timeRight {
              width: 50%;
              height: 58px;
              color: rgba(0, 0, 0, 0.85);
              border-right: 1px solid rgba(0, 0, 0, 0.08);

              p {
                text-align: center;
              }

              .titled {
                margin: 5px auto;
                font-size: 16px;
                font-weight: 500;
              }

              .calendar {
                font-size: 14px;
                font-weight: 400;
              }
            }

            .bg-blue {
              color: #fff;
              background-color: #1890ff;

              .titled {
                margin: 5px auto;
                font-size: 16px;
                font-weight: 500;
              }

              .calendar {
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        .addBtn {
          width: 60px;
          height: 32px;
          margin-top: 30px;
          margin-right: 15px;
          background: #1890ff;
          border-radius: 4px;
        }
      }
    }
  }

  .section {
    display: flex;
    width: 94%;
    margin: 0px auto 30px auto;
    //flex: 1;
    background-color: #f1f4f5;
  }

  .footer {
    width: 100%;
    height: 25%;

    .footer-main {
      width: 94%;
      height: 10%;

      margin: 0 auto;

      .footer-top {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 54px;
        margin: 16px auto;
        background-color: #fff;
        border-radius: 8px;

        .icon-task {
          img {
            margin-left: 24px;
          }
        }

        .task-ipt {
          width: 54%;
          height: 100%;
          resize: none;
          border: none;
          border-radius: 8px;
          outline: none;
          box-shadow: none;
        }

        .task-pick {
          margin-right: 100px;
          border: none;
        }

        .task-btn {
          margin-right: 15px;

          border-radius: 4px;
        }
      }

      .footer-bottom {
        .task-addBtn {
          width: 100%;
          height: 54px;
          font-size: 16px;
          font-weight: 400;
          line-height: 54px;
          color: rgba(0, 0, 0, 0.45);
          cursor: pointer;
          background-color: #fff;
          border-radius: 8px;

          img {
            width: 18px;
            height: 18px;
            margin-left: 18px;
          }

          span {
            margin-left: 24px;
          }
        }
      }
    }
  }
}
</style>
