<template>
  <div class="home">
    <div class="header">
      <div class="header-main">
        <div class="header-left">
          <div class="portal">
            <img v-if="avatar" :src="avatar"/>
            <span v-else
                  class="bg-blues text-white text-lg flex align-center justify-center"
            >{{ name.substr(-2, 2) }}</span>
          </div>
          <div class="name">
            <p>
              {{ name }}
            </p>
          </div>
          <div class="achievement">
            <p>
              {{ purposevalue }}
            </p>
          </div>
        </div>
        <div class="header-right">
          <div class="task">
            <div class="left" @click="changeHandler(0)">
              <img src="../../src/assets/left.svg"/>
            </div>
            <div class="time">
              <template v-for="(item) in selectTimeList" :key="item.id">
                <div
                    :class="[activeTime === item.index ? 'bg-blue' : '']"
                    class="timeRight"
                    @click="changeTime(item.index)"
                >
                  <p class="titled">{{ item.performanceTaskBookName }}</p>
                  <p class="calendar">{{ item.timeCycleStart }}~{{ item.timeCycleEnd }}</p>
                </div>
              </template>
            </div>
            <div class="right" @click="changeHandler(1)">
              <img src="../../src/assets/right.svg"/>
            </div>
          </div>
          <a-button class="addBtn" type="primary" @click="showModal">
            新增
          </a-button>
          <a-modal v-model:visible="visible" :confirm-loading="confirmLoading" cancelText="取消"
                   okText="确定" title="新增" @ok="handleOk">
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-item label="名称">
                <a-input v-model:value="namevalues" name="name"
                         placeholder="请输入名称"/>
              </a-form-item>
              <a-form-item label="时间周期" name="time">
                <a-range-picker :placeholder="['开始时间', '结束时间']"
                                @change="onDate"/>
              </a-form-item>
              <a-form-item label="主旨">
                <a-textarea
                    v-model:value="purposevalues"
                    class="end-ipt"
                    placeholder="请输入主旨"/>
              </a-form-item>
            </a-form>
          </a-modal>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="box">
        <img src="../../src/assets/blank.svg"/>
        <p>暂无绩效任务</p>
        <div class="add-btn" @click="CreateTask()">
          <img class="plus" src="../../src/assets/plus.svg"/>
          添加绩效任务
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import {ref, watch} from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import {AddTask, AddTaskBook, getTask} from '@/api/task';
import {DepartmentId} from '@/api/dingApi';
import useTimeSelect from '@/hooks/useTimeSelect';

export default {
  data() {
    return {
      locale: zhCN,
      zhCN,
      visible: false,
      confirmLoading: false,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      namevalues: '', // 名称
      purposevalues: '', // 主旨
      seeshow: false,
      taskCycleStart: '',
      taskDescription: '', // 任务描述
      taskCycleEnd: '',
      TaskBooks: {},
    };
  },
  setup() {
    const namevalue = ref('');
    const purposevalue = ref('');
    const lastnamevalue = ref('');
    const timeCycleStart = ref(''); // 任务开始时间
    const timeCycleEnd = ref(''); // 任务结束时间
    const lastTimeCycleStart = ref('');// 上一个任务开始时间
    const lastTimeCycleEnd = ref('');// 上一个任务结束时间
    const departmentName = ref('');// 部门的名称
    const userid = sessionStorage.getItem('UserId');

    // 时间选择抽离
    const {
      activeTime, timeList, selectTimeList, name, changeHandler, changeTime, avatars,
    } = useTimeSelect();

    // 监听下标 变化
    watch(() => activeTime.value, (newVal) => {
      purposevalue.value = timeList.value[newVal].keynote;
    });

    // 获取部门的信息
    DepartmentId({
      userid,
    }).then((res) => {
      // console.log(res.data.data);
      departmentName.value = res.data.data.name;
    });
    // 暴露给模板
    return {
      name,
      namevalue,
      purposevalue,
      timeCycleStart,
      timeCycleEnd,
      lastTimeCycleStart,
      lastTimeCycleEnd,
      lastnamevalue,
      avatars,
      selectTimeList,
      timeList,
      activeTime,
      changeTime,
      changeHandler,
      departmentName,
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    openShow() {
      this.seeshow = true;
    },
    // 获取任务时间
    onDates(date) {
      this.taskCycleStart = date[0].format('YYYY-MM-DD ');
      this.taskCycleEnd = date[1].format('YYYY-MM-DD ');
      console.log('startTaskTime', this.taskCycleStart);
      console.log('endTaskTime', this.taskCycleEnd);
    },
    // 任务保存
    addTasks() {
      this.seeshow = false;
      const userid = sessionStorage.getItem('UserId');

      AddTask({
        taskDescription: this.taskDescription,
        taskCycleStart: this.taskCycleStart,
        taskCycleEnd: this.taskCycleEnd,
        publisher: this.name,
        publisherId: userid,
        performanceTaskBookId: 39,
      }).then((res) => {
        console.log(res);
        this.taskDescription = null;
      });
    },
    // 获取任务书时间
    onDate(date) {
      this.timeCycleStart = date[0].format('YYYY-MM-DD ');
      this.timeCycleEnd = date[1].format('YYYY-MM-DD ');
      console.log('startTime', this.timeCycleStart);
      console.log('endTime', this.timeCycleEnd);
    },
    // modal框的确认按钮
    handleOk() {
      // this.$refs.ruleForm.validate().then(() => {
      //   console.log('value', this.form);
      // }).catch((error) => {
      //   console.log('error', error);
      // });
      this.confirmLoading = true;
      // 新建任务书
      const userid = sessionStorage.getItem('UserId');
      AddTaskBook({
        performanceTaskBookName: this.namevalues,
        keynote: this.purposevalues,
        timeCycleStart: this.timeCycleStart,
        timeCycleEnd: this.timeCycleEnd,
        publisherId: userid,
        publisher: this.name,
      }).then((res) => {
        console.log(res);
        this.visible = false;
        this.confirmLoading = false;
        getTask(0).then((resd) => {
          console.log(resd);
        });
      });
    },
    CreateTask() {
      console.log(this);
      this.$router.push('/');
    },
  },
};
</script>
<style lang="less" scoped>

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #F1F4F5;

  .header {
    width: 100%;
    height: 92px;
    background-color: #fff;

    .header-main {
      display: flex;
      width: 94%;
      height: 92px;
      margin: 0 auto;

      .header-left {
        display: flex;
        width: 50%;
        height: 92px;

        img {
          width: 52px;
          height: 52px;
          margin-top: 20px;
          border-radius: 8px;
        }

        span {
          display: block;
          width: 52px;
          height: 52px;
          margin-top: 20px;
          border-radius: 8px;
        }

        .name {
          margin-top: 32px;
          margin-left: 16px;
          font-size: 20px;
          font-weight: 500;

          color: rgba(0, 0, 0, 0.85);
        }

        .achievement {
          width: auto;
          height: 22px;
          margin-top: 35px;
          margin-left: 32px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .header-right {
        display: flex;
        justify-content: space-between;
        width: 50%;
        height: 100%;

        .task {
          display: flex;
          min-width: 538px;
          height: 60px;
          margin-top: 16px;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.08);
          border-radius: 8px;

          .left {
            position: relative;
            width: 58px;
            height: 58px;
            border-right: 1px solid rgba(0, 0, 0, 0.08);

            img {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }

          .right {
            position: relative;
            width: 58px;
            height: 58px;

            img {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }

          .time {
            display: flex;
            flex: 1;

            .timeLeft {
              width: 50%;
              height: 58px;
              border-right: 1px solid rgba(0, 0, 0, 0.08);
              border-left: 1px solid rgba(0, 0, 0, 0.08);

              p {
                text-align: center;
              }

              .titled1 {
                margin: 5px auto;
                font-size: 16px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
              }

              .calendar1 {
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
              }

            }

            .timeRight {
              width: 50%;
              height: 58px;
              color: rgba(0, 0, 0, 0.85);
              border-right: 1px solid rgba(0, 0, 0, 0.08);

              p {
                text-align: center;
              }

              .titled {
                margin: 5px auto;
                font-size: 16px;
                font-weight: 500;
              }

              .calendar {
                font-size: 14px;
                font-weight: 400;
              }

            }

            .bg-blue {
              color: #fff;
              background-color: #1890ff;

              .titled {
                margin: 5px auto;
                font-size: 16px;
                font-weight: 500;
              }

              .calendar {
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        .addBtn {
          width: 60px;
          height: 32px;
          margin-top: 30px;
          margin-right: 15px;
          background: #1890ff;
          border-radius: 4px;
        }
      }
    }
  }

  .section {
    display: flex;
    flex: 1;
    width: 94%;
    margin: 30px auto;

    .box {
      width: 214px;
      height: 593px;
      margin: 96px auto;
      text-align: center;

      p {
        margin: 16px auto;
        font-size: 20px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
      }

      .add-btn {
        width: 158px;
        height: 40px;
        margin: 24px auto;
        font-size: 16px;
        line-height: 40px;
        color: #fff;
        text-align: center;
        background: #1890ff;
        border-radius: 4px;

        .plus {
          margin-top: -5px;
          //margin-left: -1px;
        }
      }
    }
  }

}
</style>
