import {selectLinkman} from '@/api/task'
import {onBeforeMount, ref, watch} from 'vue'

const useSearch = (userInfo) => {
  const { userid, department } = userInfo
  const allList = ref([])
  const list = ref([])

  const userLoading = ref(false)
  onBeforeMount(() => {
    const allUserList = sessionStorage.getItem('allList')
    if (allUserList) {
      allList.value = JSON.parse(allUserList)
    }
    selectLinkman({
      userid, deptId: department[0]
    }).then((res) => {
      if (res.data.code === 200) {
        allList.value = res.data.data
        sessionStorage.setItem('allList', JSON.stringify(res.data.data))
      }
    })
  })

  const time = ref(null)

  /**
   * 对象数组去重
   * @param {Array} arr
   * @param {String} u_key
   */
  function unique(arr,u_key) {
    let map = new Map()
    arr.forEach((item,index)=>{
      if (!map.has(item[u_key])){
        map.set(item[u_key],item)
      }
    })
    return [...map.values()]
  }

  /**
   *  搜索
   * @param {*} param0
   */
  const onChange = ({userid, deptId, name}) => {
    userLoading.value = true
    if (!name) {
      userLoading.value = false
      return
    }
    clearTimeout(time.value)
    time.value = setTimeout(() => {
      let arr = []
      arr = allList.value.filter((item) => {
        // const strArr = item.name.split("");
        // const nameIsIn = name.search(strArr[0]) >= 0 || item.name.search(name) >= 0
        const nameIsIn =  item.name.search(name) >= 0
        return nameIsIn
      })
      list.value = unique(arr, 'userid')
      userLoading.value = false
      clearTimeout(time.value)
    }, 500)
  }
  return [list, onChange, userLoading]
}

export {
  useSearch
}
