<template>
  <div class="home">
    <div class="header">
      <div class="header-main">
        <div class="header-left">
          <div class="portal">
            <img v-if="avatar" :src="avatar"/>
            <span v-else
                  class="bg-blues text-white text-lg flex align-center justify-center"
            >{{ name.substr(-2, 2) }}</span>
          </div>
          <div class="name">
            <p>
              {{ name }}
            </p>
          </div>
          <div class="achievement">
            <p>
              {{ purposevalue }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="main">
        <p class="title">创建绩效任务本</p>
        <AddTask/>
      </div>
    </div>
  </div>
</template>
<script>
import {ref} from 'vue';
import {AddTaskBook} from '@/api/task';
import AddTask from '@/views/AddTaskForm/AddTask.vue';

export default {
  components: {AddTask},
  data() {
    return {
      visible: false,
      confirmLoading: false,
      labelCol: {span: 4},
      wrapperCol: {span: 24},
      timeCycleStart: '',
      timeCycleEnd: '',
      namevalues: '', // 名称
      purposevalues: '', // 主旨
      seeshow: false,
      taskCycleStart: '',
      taskDescription: '', // 任务描述
      taskCycleEnd: '',
      TaskBooks: {},
      form: {
        name: '',
        date1: undefined,
      },
      rules: {
        name: [
          {required: true, message: '请输入绩效任务本', trigger: 'blur'},
        ],
        date1: [
          {
            required: true, message: '请选择日期', trigger: 'change', type: 'array',
          },
        ],
      },
    };
  },
  setup() {
    const namevalue = ref('');
    const purposevalue = ref('');
    const lastnamevalue = ref('');
    const lastTimeCycleStart = ref('');// 上一个任务开始时间
    const lastTimeCycleEnd = ref('');// 上一个任务结束时间
    const {name, avatar} = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo'));

    // 暴露给模板
    return {
      namevalue,
      name,
      avatar,
      purposevalue,
      lastTimeCycleStart,
      lastTimeCycleEnd,
      lastnamevalue,
    };
  },
  methods: {
    onDate(date, dateString) {
      console.log(date);
      console.log(dateString);
      this.timeCycleStart = date[0].format('YYYY-MM-DD ');
      this.timeCycleEnd = date[1].format('YYYY-MM-DD ');
      console.log('startTime', this.timeCycleStart);
      console.log('endTime', this.timeCycleEnd);
    },
    Gohome() {
      this.$refs.ruleForm
      .validate()
      .then(() => {
        AddTaskBook({
          performanceTaskBookName: this.form.name,
          keynote: this.purposevalues,
          timeCycleStart: this.form.date1[0].format('YYYY-MM-DD'),
          timeCycleEnd: this.form.date1[1].format('YYYY-MM-DD'),
        }).then((res) => {
          console.log(res);
            if (res.data.code === 200) {
            this.$router.push('/');
          }
        });
        console.log('values', this.form);
      })
      .catch((error) => {
        console.log('error', error);
      });
    },
  },
};
</script>
<style lang="less" scoped>

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #F1F4F5;

  .header {
    width: 100%;
    height: 92px;
    background-color: #fff;

    .header-main {
      display: flex;
      width: 94%;
      height: 92px;
      margin: 0 auto;

      .header-left {
        display: flex;
        width: 50%;
        height: 92px;

        img {
          width: 52px;
          height: 52px;
          margin-top: 20px;
          border-radius: 8px;
        }

        span {
          display: block;
          width: 52px;
          height: 52px;
          margin-top: 20px;
          border-radius: 8px;
        }

        .name {
          margin-top: 32px;
          margin-left: 16px;
          font-size: 20px;
          font-weight: 500;

          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }

  .section {
    display: flex;
    flex: 1;
    width: 94%;

    .main {
      width: 280px;
      height: 430px;
      margin: 78px auto;

      .title {
        font-size: 30px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
      }

      .mingcheng {
        .end-ipt {
          height: 76px;
          margin: 0;
          text-align: left;
          resize: none;

          input, button, select, textarea {
            outline: none
          }
        }

        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
        }

        .createBtn {
          width: 280px;
          height: 28px;
          margin-top: 56px;
          background: #1890ff;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
