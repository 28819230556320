<template>
  <a-col class="todoTasksList" span="24">
    <div class="padding-top">
      <a-month-picker v-model:value="monthTime" :format="'YYYY/MM'" style="border-radius: 10px"
                      @change="changeTaskGet(timeSelect.id)"/>
      <a-button
          class="margin-left border"
          ghost
          :loading="exportLoading"
          style="color: rgba(24,144,255,1); border-color: rgba(24,144,255,1); border-radius: 5px"
          @click="downloadFile"
      >
        <template #icon>
          <FileExcelFilled/>
        </template>
        导出
      </a-button>
    </div>
    <a-spin :spinning="loading">
      <template v-for="(item, index) in todoTasksLists" :key="item.id">
        <TaskBlock
            :imgSrc="require('@/assets/flag.svg')"
            :imgSrcTwo="require('@/assets/transmit.svg')"
            :index="index"
            :item="item"
        />
      </template>
      <template v-for="(item, index) in sponsorTaskList" :key="item.id">
        <TaskBlock
            :imgSrc="require('@/assets/create.svg')"
            :imgSrcTwo="require('@/assets/start.svg')"
            :index="index"
            :item="item"
        />
      </template>
      <template v-for="(item, index) in untodoTasksLists" :key="item.id">
        <TaskBlock
            :imgSrc="require('@/assets/flag.svg')"
            :imgSrcTwo="require('@/assets/transmit.svg')"
            :index="index"
            :item="item"
        />
      </template>
      <template v-for="(item, index) in unsponsorTaskList" :key="item.id">
        <TaskBlock
            :imgSrc="require('@/assets/create.svg')"
            :imgSrcTwo="require('@/assets/start.svg')"
            :index="index"
            :item="item"
        />
      </template>
      <div
          v-if="todoTasksLists.length === 0 && sponsorTaskList.length === 0 && untodoTasksLists.length === 0 &&  unsponsorTaskList.length === 0"
          class="section"
      >
        <div class="box">
          <img src="@/assets/blank.svg"/>
          <p>暂无绩效任务</p>
       
        </div>
      </div>
    </a-spin>
  </a-col>
  <a-modal
      v-model:visible="visible"
      :footer="null"
      :width="920"
      centered
      destroyOnClose
  >
    <template #title>
      <a-space align="center">
        <img
            class="cursor-pt"
            src="@/assets/tree.svg"
        />
        <span class="text-bold text-lg">
              任务树
            </span>
      </a-space>
    </template>
    <a-spin :spinning="treeLoading">
      <a-tree
          :defaultExpandAll="true"
          :tree-data="treeList"
          blockNode
          @expand="expand"
      >
        <template v-if="treeList" #title="{ slots }">
          <div class="flex justify-between">
               <a-tooltip>
            <template #title>
              {{ slots?.title?.taskDescription }}
            </template>
               <div  style="width: 44%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                  {{ slots?.title?.taskDescription }}
              </div>
          </a-tooltip>
            <div class="flex">
              <a-tag v-if="slots?.title?.taskState" :color="tagColorTypes[slots?.title?.taskState]['type']">
                {{ tagColorTypes[slots?.title?.taskState]['title'] }}
              </a-tag>
              <span class="padding-lr-lg">{{ slots.title.taskCycleStart }}~{{ slots.title.taskCycleEnd }}</span>
              <div class="task-start flex align-center">
                <img v-if="!slots.title.isRelease" class="padding-lr-xs" src="@/assets/check.svg"/>
                <img v-if="slots.title.isRelease" class="padding-lr-xs" src="@/assets/start.svg"/>
                <!--              {{ slots?.title?.operatorId === userid }}-->
                <span class="padding-lr-xs">{{
                    !slots.title.isRelease ? slots?.title?.departmentName + "/" + slots?.title?.operator : '发起'
                  }}</span>
                <div v-if="slots.title.isRelease">
                  <img class="padding-lr-xs" src="@/assets/transmit.svg"/>
                  {{ slots?.title?.departmentName }}
                </div>
                <!--              {{ slots?.title?.operator }}-->
              </div>
            </div>
          </div>
        </template>
      </a-tree>
    </a-spin>

  </a-modal>
</template>

<script>
import {defineComponent, inject, onBeforeMount, provide, ref, watch} from 'vue';
import TaskBlock from './components/TaskBlock/index';
import {ChangeTask, getExport, getTaskTrees} from '@/api/task';
import {message} from 'ant-design-vue';
// import TreeItemBlock from './components/TreeItemBlock.vue';
import {FileExcelFilled} from '@ant-design/icons-vue';


export default defineComponent({
  name: 'index',
  components: {
    TaskBlock,
    FileExcelFilled,
    // MinusCircleOutlined
    // TreeItemBlock,
  },
  props: {
    timeSelect: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const performanceTaskBooks = ref(props);
    provide('performanceTaskBook', performanceTaskBooks);
    const userInfo = inject('userInfo')
    const userid = userInfo?.userid?.value;

    const loading = ref(false);
//已完成的任务
    const sponsorTaskList = ref([]); // 被指派任务
    const todoTasksLists = ref([]); // 自己创建的任务
//未完成的任务
    const unsponsorTaskList = ref([]); // 被指派任务
    const untodoTasksLists = ref([]); // 自己创建的任务
    const monthTime = ref(null); // 选择的月份
    // 获取数据
    const changeTaskGet = (id) => {
      loading.value = true;
      let time = {};
      if (monthTime.value) {
        time = {
          timeStart: monthTime.value.startOf('month').startOf('day').format('YYYY-MM-DD'),
          timeEnd: monthTime.value.endOf('month').startOf('day').format('YYYY-MM-DD'),
        }
      }
      ChangeTask({
        userid,
        // eslint-disable-next-line no-undef
        id,
        isNotMe: 0,
        ...time
      })
      .then((res) => {
        loading.value = false;
        if (res.data.code === 200) {
          if(res.data.data.completePerformance.sponsorTaskList ){
                     sponsorTaskList.value =[...res.data.data.completePerformance.sponsorTaskList] || [] ;        
              }
             if(res.data.data.undonePerformance.sponsorTaskList){
                     unsponsorTaskList.value = [...res.data.data.undonePerformance.sponsorTaskList] || [];  
              }
              if(res.data.data.undonePerformance.todoTasksList) {
                      untodoTasksLists.value =  [...res.data.data.undonePerformance.todoTasksList] || [];
              }
               if(res.data.data.completePerformance.todoTasksList){
                     todoTasksLists.value = [...res.data.data.completePerformance.todoTasksList] || [];
              }
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    };
    // 向下级传递 获取任务列表事件
    provide('changeTaskGet', () => {
      changeTaskGet(props.timeSelect.id);
    });
    onBeforeMount(() => {
      // 初始获取
      changeTaskGet(props.timeSelect.id);
    });
    watch(
        () => props.timeSelect,
        (newVal) => {
          changeTaskGet(newVal.id);
          monthTime.value = null
        },
    );

    const treeList = ref([{}]);

    /**
     *
     * @param children
     * @returns []
     */
    const filterTree = (children) => {
      if (!children) return children;
      return children.map((item) => {
        return ({
          key: item.id,
          ...item,
          slots: {
            title: item,
            icon: item,
          },
          title: item.taskDescription,
          children: filterTree(item?.taskTreeList),
        })
      });
    };
    // onBeforeMount(() => {
    //   treeList.value = filterTree(treeList.value);
    // });

    const visible = ref(false);
    const treeLoading = ref(false);
    const changeOpenTreeModal = async (data) => {
      // console.log(data);
      treeLoading.value = true;
      await getTaskTrees({id: data.id}).then((res) => {
        if (res.data.code === 200) {
          // treeList.value = [res.data.data];
          let resData = res.data.data;
          resData.isRelease = true
          let list = filterTree([resData])
          // list[0].isRelease = true
          treeList.value = list;
          console.log(treeList.value)
        }
        treeLoading.value = false;
        visible.value = true;
      });

    };
    provide('changeOpenTreeModal', {changeOpenTreeModal, treeLoading}); // 向子组件传递弹窗值
    const expandedKeys = ref([]);
    const expand = (keys) => {
      expandedKeys.value = keys;
    };
    // 任务状态
    const tagColorTypes = {
      0: {
        type: 'orange',
        title: '未读',
      },
      1: {
        type: 'blue',
        title: '执行中',
      },
      2: {
        type: 'red',
        title: '待核查',
      },
      3: {
        type: 'green',
        title: '已完成',
      },
    };

    const exportLoading = ref(false);
    const downloadFile = () => {
      exportLoading.value = true
      let time = {};
      if (monthTime.value) {
        time = {
          timeStart: monthTime.value.startOf('month').startOf('day').format('YYYY-MM-DD'),
          timeEnd: monthTime.value.endOf('month').startOf('day').format('YYYY-MM-DD'),
        }
      }
      getExport({userid: userid, bookId: performanceTaskBooks.value.timeSelect.id, ...time}).then(res => {
        // 职员名称-绩效任务本名「筛选月份:可选」绩效任务报告
        let fileName = `${userInfo.name.value}-${performanceTaskBooks.value.timeSelect.performanceTaskBookName}${monthTime.value ? ('-' + monthTime.value.format('YYYY-MM') + '月') : ''}-绩效任务报告`,
        a = document.createElement('a'),
        //ArrayBuffer 转为 Blob
        blob = new Blob([res.data], {type: "application/vnd.ms-excel"}),
        objectUrl = URL.createObjectURL(blob);

        a.setAttribute("href", objectUrl);
        a.setAttribute("download", fileName + '.xls');
        a.click();
        exportLoading.value = false
      })
    }

    return {
      loading,
      sponsorTaskList,
      todoTasksLists,
      unsponsorTaskList,
      untodoTasksLists,
      userid,
      monthTime,
      changeTaskGet,
      treeList,
      visible,
      treeLoading,
      expand,
      expandedKeys,
      tagColorTypes,
      downloadFile,
      exportLoading
    };
  },
});
</script>

<style lang="less" scoped>
.todoTasksList {
  ::v-deep(.ant-calendar-picker) {
    .ant-calendar-picker-input {
      border-radius: 10px;
    }
  }
}

.section {
  display: flex;
  flex: 1;
  width: 94%;
  margin: 30px auto;

  .box {
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 403px;
    margin: 96px auto;
    text-align: center;

    p {
      width: 260px;
      margin: 10px auto;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.45);
      text-align: center;
    }
  }

  .add-btn {
    width: 158px;
    height: 40px;
    margin: 24px auto;
    font-size: 16px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background: #1890ff;
    border-radius: 4px;

    .plus {
      margin-top: -5px;
      //margin-left: -1px;
    }
  }
}
</style>
